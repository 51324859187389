import React from 'react';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'hooks';

interface Props {
  onChange: (val: string[]) => void;
  value: string[];
}

const MediaSentimentTagsFilter = (props: Props) => {
  const { t } = useIntl();

  const { onChange, value } = props;

  const toggleType = (type: string) => {
    if (value.some((v) => v === type)) {
      onChange(value.filter((v) => v !== type));
    } else {
      onChange([...value, type]);
    }
  };

  const types = ['positive', 'negative', 'neutral'];

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="MediaLibrarySentimentTagsFilter__Label" />
      </Form.Label>

      {types.map((type) => (
        <Form.Option key={type}>
          <Form.Checkbox
            checked={value.some((v) => v === type)}
            onChange={() => toggleType(type)}
          />
          <span>{t(`MediaLibrarySentimentTagsFilter__${type}`)}</span>
        </Form.Option>
      ))}
    </Form.Field>
  );
};

export default MediaSentimentTagsFilter;
