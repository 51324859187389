import React from 'react';
import includes from 'lodash/includes';
import { connect } from 'react-redux';
import { RootState } from 'redux/ducks';
import { startMediaLoading } from 'redux/ducks/ui';
import DOWNLOAD_MEDIA from 'graphql/mutations/downloadMedia.graphql';
import { flashMessage } from 'helpers';
import { useMutation } from '@apollo/react-hooks';

type RenderProp = (
  downloadMedia: () => void,
  { isLoading }: { isLoading: boolean }
) => React.ReactNode;

type IDs = number | string | (number | string)[];

interface OwnProps {
  suggestionIds?: IDs;
  attachmentIds?: IDs;
  requestSource: string;
  children: RenderProp;
}

interface ConnectedState {
  socketId: string;
  isLoading: boolean;
}

interface ConnectedActions {
  startLoading: (requestSource: string) => void;
}

interface MutationData {
  downloadMedia: {
    url: string;
  };
}

type Props = OwnProps & ConnectedState & ConnectedActions;

function MediaDownloader(props: Props) {
  const {
    suggestionIds,
    attachmentIds,
    requestSource,
    children,
    socketId,
    isLoading,
    startLoading,
  } = props;

  const [downloadMedia, { loading: isMutating }] = useMutation<MutationData>(
    DOWNLOAD_MEDIA,
    {
      variables: { socketId, suggestionIds, attachmentIds, requestSource },
      onCompleted: (data) => {
        if (data && data.downloadMedia) {
          const { url } = data.downloadMedia;
          if (url) {
            window.location.href = url;
          } else {
            flashMessage('MediaDownloader__PreparingDownload');
            startLoading(requestSource);
          }
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
    }
  );

  return <>{children(downloadMedia, { isLoading: isLoading || isMutating })}</>;
}

const mapStateToProps = (state: RootState, { requestSource }: OwnProps) => {
  return {
    socketId: state.socket.id || '',
    isLoading: includes(state.ui.activeMediaDownloads, requestSource),
  };
};

const mapDispatchToProps = {
  startLoading: startMediaLoading,
};

export default connect<ConnectedState, ConnectedActions, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(MediaDownloader);
