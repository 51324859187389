mutation AddAttachmentsToAttachmentAlbums(
  $attachmentIds: [ID!]!
  $attachmentAlbumIds: [ID!]!
) {
  addAttachmentsToAttachmentAlbums(
    attachmentIds: $attachmentIds
    attachmentAlbumIds: $attachmentAlbumIds
  ) {
    success
    errors
  }
}
