mutation ValidateOneTimePassword($mfaProtocol: String!, $token: String!) {
  validateOneTimePassword(mfaProtocol: $mfaProtocol, token: $token) {
    valid
    errors
    jwt
    user {
      id
      authyId
      enableMfa
      mfaPhone
      mfaProtocol
    }
  }
}
