mutation SetSuggestionCampaign($campaignId: ID, $suggestionIds: [ID!]!) {
  setSuggestionCampaign(
    campaignId: $campaignId
    suggestionIds: $suggestionIds
  ) {
    suggestions {
      id
      updatedAt
      campaign {
        id
        name
      }
    }
    errors
  }
}
