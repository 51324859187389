import React, { useState } from 'react';
import { SavedFilterSetsProps } from './SavedFilterSets';
import Button from 'components/Button';
import ConfirmModal from 'components/ConfirmModal';
import { FilterPillsDisplayContext } from 'components/FilterPills/FilterPills';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import SavedFilterSet from 'types/SavedFilterSet';
import { parseFilter } from './parseFilter';
import { REMOVE_SAVED_FILTER_SET, UPDATE_SAVED_FILTER_SET } from './queries';

interface SavedFilterSetItemProps {
  savedFilterSet: SavedFilterSet;
  updateFilter: SavedFilterSetsProps['updateFilter'];
  defaultFilter: SavedFilterSetsProps['defaultFilter'];
  onApply: () => void;
  renderFilterPills: SavedFilterSetsProps['renderFilterPills'];
  isActive: boolean;
}

export default function SavedFilterSetItem(props: SavedFilterSetItemProps) {
  const {
    savedFilterSet,
    updateFilter,
    onApply,
    renderFilterPills,
    isActive,
    defaultFilter,
  } = props;
  const { t } = useIntl();
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const filter = { ...defaultFilter, ...parseFilter(savedFilterSet.data) };

  const [updateSavedFilterSet] = useMutation(UPDATE_SAVED_FILTER_SET, {
    onCompleted: (data) => {
      if (data.updateSavedFilterSet.errors) {
        console.error(data.updateSavedFilterSet.errors);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      }
    },
    onError: (e) => {
      console.error(e);
      flashMessage('Global__UnexpectedError', { style: 'error' });
    },
  });

  const [removeSavedFilterSet, { loading }] = useMutation(
    REMOVE_SAVED_FILTER_SET,
    {
      variables: { id: savedFilterSet.id },
      refetchQueries: ['SavedFilterSets'],
      onCompleted: (data) => {
        if (data.removeSavedFilterSet.errors) {
          console.error(data.removeSavedFilterSet.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        } else {
          flashMessage('SavedFilterSets__DeleteSuccess');
        }
      },
      onError: (e) => {
        console.error(e);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
    }
  );

  const handleApply = () => {
    updateFilter(filter);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('savedFilterSetId', savedFilterSet.id.toString());
    history.replace({ search: searchParams.toString() });
    onApply();
  };

  const handleClear = () => {
    updateFilter(defaultFilter);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('savedFilterSetId');
    history.replace({ search: searchParams.toString() });
    onApply();
  };

  return (
    <>
      <div className="border-b-default mb-2 pb-2 last:mb-0 last:border-b-0 last:pb-0">
        <div className="mb-1.5 flex items-center gap-0.5 font-bold text-dark">
          {isActive && (
            <div className="h-1 w-1 flex-shrink-0 rounded-full bg-socialiePink" />
          )}
          <span
            className="bump-up-1 rounded p-px leading-none ring-offset-4 focus:ring-1"
            contentEditable
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            dangerouslySetInnerHTML={{ __html: savedFilterSet.name }}
            onBlur={(e) => {
              if (e.currentTarget.innerText === savedFilterSet.name) return;
              updateSavedFilterSet({
                variables: {
                  id: savedFilterSet.id,
                  values: { name: e.currentTarget.innerText },
                },
              });
            }}
          />
        </div>

        <div>
          <FilterPillsDisplayContext.Provider value="savedFilterSet">
            {renderFilterPills(filter)}
          </FilterPillsDisplayContext.Provider>
        </div>
        <div className="flex w-full items-center gap-1">
          <div className="flex-1">
            {isActive ? (
              <Button filledBg onClick={handleClear}>
                {t('SavedFilterSets__ClearButton')}
              </Button>
            ) : (
              <Button filledGreyBg onClick={handleApply}>
                {t('SavedFilterSets__ApplyButton')}
              </Button>
            )}
          </div>

          <div className="flex-1">
            <Button mono onClick={() => setIsDeleteModalOpen(true)}>
              {t('Global__Delete')}
            </Button>
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={removeSavedFilterSet}
        isConfirmDisabled={loading}
        isLoading={loading}
      >
        {t('SavedFilterSets__ConfirmDeleteBody', {
          name: savedFilterSet.name,
        })}
      </ConfirmModal>
    </>
  );
}
