#import "../fragments/CurrentUserFields.graphql"
#import "../fragments/SemiLoggedInUserFields.graphql"

query CurrentUserQuery {
  currentUser {
    ...CurrentUserFields
  }

  semiLoggedInUser {
    ...SemiLoggedInUserFields
  }
}
