mutation MarkChatRead($id: ID!) {
  markChatRead(id: $id) {
    errors
    messages {
      id
      readBySuggester
    }
    suggester {
      id
      hasUnreadChatMessages
    }
  }
}
