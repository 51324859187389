import React, { useState, useEffect } from 'react';
import Suggestion from 'types/Suggestion';
import ADD_TAGS_AUTOMATICALLY from 'graphql/mutations/addTagsAutomatically.graphql';
import { useMutation } from '@apollo/react-hooks';
import useWaitForSave from 'screens/DraftBuilder/hooks/useWaitForSave';
import { useIntl } from 'hooks';
import QTooltip from 'components/QTooltip';
import { IconCheckmark, IconLoader } from 'icons';

interface Props {
  suggestions: Suggestion[];
}

export default function AddTagsAutomatically(props: Props) {
  const { suggestions } = props;
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    let timer: number;
    if (isDone) timer = setTimeout(() => setIsDone(false), 4000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isDone]);
  const hasAttachments = suggestions.find((s) => !!s.attachments.length);
  const { t } = useIntl();
  const [mutation, { loading }] = useMutation(ADD_TAGS_AUTOMATICALLY, {
    variables: { ids: suggestions.map((s) => s.id) },
    onCompleted: (data) => {
      if (!data.addTagsAutomatically.errors) setIsDone(true);
    },
  });

  const [addTagsAutomatically, isWaiting] = useWaitForSave(mutation);

  const isLoading = loading || isWaiting;

  const handleClick = () => {
    setIsDone(false);
    addTagsAutomatically();
  };

  if (!hasAttachments) return null;

  return (
    <div className="-mt-1 mb-3">
      <button
        className="text-sm text-socialiePink"
        data-qa="suggestion-form-add-tags-automatically-button"
        onClick={handleClick}
        disabled={isLoading}
      >
        <span className="flex items-center">
          {t('SuggestionForm__AddTagsAutomatically')}
          <QTooltip
            content={t('SuggestionForm__AddTagsAutomatically--tooltip')}
          />
          {isLoading ? (
            <IconLoader className="ml-1 h-2 w-2 text-default" />
          ) : isDone ? (
            <IconCheckmark className="ml-1 h-2 w-2 text-success" />
          ) : null}
        </span>
      </button>
    </div>
  );
}
