mutation removeSuggestionAlbum($suggestionIds: [ID!]!, $albumIds: [ID!]!) {
  removeSuggestionAlbum(albumIds: $albumIds, suggestionIds: $suggestionIds) {
    suggestions {
      id
      updatedAt
      albums {
        id
        name
      }
    }
  }
}
