#import '../fragments/AttachmentFields.graphql'
#import '../fragments/AttachmentDetailFields.graphql'
#import '../fragments/AttachmentSuggestionFields.graphql'

query AttachmentsById($ids: [ID!]!) {
  attachments(ids: $ids) {
    edges {
      node {
        ...AttachmentFields
        ...AttachmentDetailFields
        suggestions {
          ...AttachmentSuggestionFields
        }
      }
    }
  }
}
