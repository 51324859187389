import { default as twitter } from './twitter.svg?react';
import { default as facebook } from './facebook.svg?react';
import { default as instagram } from './instagram.svg?react';
import { default as instagram_stories } from './instagram-stories.svg?react';
import { default as linkedin } from './linkedin.svg?react';

const platformIcons = {
  twitter,
  facebook,
  instagram,
  linkedin,
  instagram_basic: instagram,
  instagram_graph: instagram,
  instagram_stories: instagram_stories,
};

export default platformIcons;
