import React, { useReducer } from 'react';
import { useIntl } from 'hooks';
import { createSlice, PayloadAction } from 'redux-starter-kit';
import { Button } from 'components';
import { IconCheckmark, IconLoader } from 'icons';
import { useMutation } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';
import ENABLE_MULTIFACTOR_AUTH from 'graphql/mutations/enableMultiFactorAuth.graphql';
import {
  RecoveryCode,
  VerifyApp,
  VerifySms,
  FinishMfaSetup,
} from './components';

interface Props {
  onMfaSetupComplete: () => void;
  recoveryCodePassword: string;
  onSetIsPreventingUnload: () => void;
}

const initialState = {
  hasChosenProtocol: false,
  success: false,
  mfaProtocol: '',
  mfaPhone: '',
  qrCodeImg: '',
  subStep: 0,
};

const { reducer, actions } = createSlice({
  name: 'chooseProtocolReducer',
  initialState,
  reducers: {
    updateProtocol: (state, action: PayloadAction<string>) => {
      state.mfaProtocol = action.payload;
    },
    successfulProtocol: (
      state,
      action: { payload: { qrImg: string; success: boolean; mfaPhone: string } }
    ) => {
      state.qrCodeImg = action.payload.qrImg;
      state.success = action.payload.success;
      state.mfaPhone = action.payload.mfaPhone;
      state.hasChosenProtocol = true;
      state.subStep = 0;
    },
    setSubStep: (state, action: PayloadAction<number>) => {
      state.subStep = action.payload;
    },
    returnToChooseProtocol: (state) => {
      state.subStep = 0;
      state.success = false;
      state.hasChosenProtocol = false;
    },
  },
});

export default function ChooseProtocol(props: Props) {
  const { onMfaSetupComplete, recoveryCodePassword, onSetIsPreventingUnload } =
    props;
  const { t } = useIntl();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [enableMultiFactorAuth, { loading }] = useMutation(
    ENABLE_MULTIFACTOR_AUTH,
    {
      variables: {
        mfaProtocol: state.mfaProtocol,
      },
      onCompleted: (data) => {
        if (data && data.enableMultiFactorAuth.success) {
          dispatch(
            actions.successfulProtocol({
              qrImg: data.enableMultiFactorAuth.qrCodeImg,
              success: data.enableMultiFactorAuth.success,
              mfaPhone: data.enableMultiFactorAuth.cellphone,
            })
          );
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
      onError: () => {
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
    }
  );

  const steps =
    state.mfaProtocol === 'app'
      ? ['verifyApp', 'recoveryCode', 'finishMfaSetup']
      : ['verifySms', 'recoveryCode', 'finishMfaSetup'];

  const currentStep = steps[state.subStep];

  return (
    <>
      {!state.hasChosenProtocol && !state.success && (
        <div className="text-left">
          <div className="pb-3">
            <p className="text-dark">
              {t('Security__ManageMfa--ChooseProtocol-Desc')}
            </p>
          </div>
          <ProtocolContainer
            onClick={() => dispatch(actions.updateProtocol('sms'))}
            label={t('Security__ManageMfa--ChooseProtocol-ProtocolSms-Label')}
            subLabel={t(
              'Security__ManageMfa--ChooseProtocol-ProtocolSms-SubLabel'
            )}
            isChosen={state.mfaProtocol === 'sms'}
          />
          <ProtocolContainer
            onClick={() => dispatch(actions.updateProtocol('app'))}
            label={t('Security__ManageMfa--ChooseProtocol-ProtocolApp-Label')}
            subLabel={t(
              'Security__ManageMfa--ChooseProtocol-ProtocolApp-SubLabel'
            )}
            isChosen={state.mfaProtocol === 'app'}
          />
          <div className="mt-3 flex items-center justify-end">
            <Button
              filledBg
              inlineBlock
              onClick={() => enableMultiFactorAuth()}
              disabled={!state.mfaProtocol.length || loading}
            >
              <span className="flex items-center">
                {loading && <IconLoader className="mr-1 h-2 w-2" />}
                {t('Global__Next')}
              </span>
            </Button>
          </div>
        </div>
      )}

      {state.hasChosenProtocol && state.success && (
        <>
          {currentStep === 'verifyApp' ? (
            <VerifyApp
              protocol={state.mfaProtocol}
              qrCodeUrl={state.qrCodeImg}
              previousStep={() => dispatch(actions.returnToChooseProtocol())}
              nextStep={() => dispatch(actions.setSubStep(state.subStep + 1))}
            />
          ) : currentStep === 'verifySms' ? (
            <VerifySms
              protocol={state.mfaProtocol}
              previousStep={() => dispatch(actions.returnToChooseProtocol())}
              nextStep={() => dispatch(actions.setSubStep(state.subStep + 1))}
              mfaPhone={state.mfaPhone}
            />
          ) : currentStep === 'recoveryCode' ? (
            <RecoveryCode
              recoveryCodePassword={recoveryCodePassword}
              protocol={state.mfaProtocol}
              mfaPhone={state.mfaPhone}
              previousStep={() =>
                dispatch(actions.setSubStep(state.subStep - 1))
              }
              nextStep={() => {
                onSetIsPreventingUnload();
                dispatch(actions.setSubStep(state.subStep + 1));
              }}
            />
          ) : currentStep === 'finishMfaSetup' ? (
            <FinishMfaSetup onMfaSetupComplete={onMfaSetupComplete} />
          ) : null}
        </>
      )}
    </>
  );
}

interface ProtocolContainerProps {
  label: string;
  subLabel: string;
  isChosen: boolean;
  onClick: () => void;
}

function ProtocolContainer({
  label,
  onClick,
  subLabel,
  isChosen,
}: ProtocolContainerProps) {
  return (
    <div
      className={`mb-2 flex cursor-pointer justify-between rounded p-2 ${
        isChosen
          ? 'border border-solid border-socialiePink'
          : 'border border-solid border-grey6'
      }`}
      onClick={onClick}
    >
      <div>
        <p className="font-bold text-dark">{label}</p>
        <p>{subLabel}</p>
      </div>
      <div className="flex w-fit items-center">
        {isChosen && (
          <IconCheckmark className="h-2.5 w-2.5 text-socialiePink" />
        )}
      </div>
    </div>
  );
}
