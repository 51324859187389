import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import AsyncPublishersAndTagsSelect, {
  usePublishersAndPublisherTagsById,
} from './AsyncPublishersAndTagsSelect';

interface Props<
  PublisherFilterKey extends string,
  PublisherTagFilterKey extends string
> {
  label: string;
  publisherFilterKey: PublisherFilterKey;
  publisherTagFilterKey: PublisherTagFilterKey;
  filter: { [key in PublisherFilterKey | PublisherTagFilterKey]: number[] };
  updateFilter: (changes: {}) => void;
  children?: React.ReactNode;
  boldLabel?: boolean;
  'data-qa'?: string;
}

export default function PublisherAndPublisherTagFilter<
  PublisherFilterKey extends string,
  PublisherTagFilterKey extends string
>(props: Props<PublisherFilterKey, PublisherTagFilterKey>) {
  const {
    label,
    publisherFilterKey,
    publisherTagFilterKey,
    filter,
    updateFilter,
    children,
    boldLabel = true,
    'data-qa': dataQa,
  } = props;
  const publisherIds = filter[publisherFilterKey];
  const publisherTagIds = filter[publisherTagFilterKey];
  const { publishers, publisherTags } = usePublishersAndPublisherTagsById({
    publisherIds,
    publisherTagIds,
  });

  return (
    <Form.Field>
      <Form.Label bold={boldLabel}>
        <FormattedMessage id={label} />
      </Form.Label>

      <AsyncPublishersAndTagsSelect
        value={{
          publishers: publishers.map((publisher) => ({
            value: publisher.id,
            label: publisher.name,
            image: publisher.image,
          })),
          publisherTags: publisherTags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          })),
        }}
        onChange={(value) => {
          updateFilter({
            [publisherFilterKey]: value.publishers.map(
              (publisher) => publisher.value
            ),
            [publisherTagFilterKey]: value.publisherTags.map(
              (tag) => tag.value
            ),
          });
        }}
        data-qa={dataQa}
      />

      {children}
    </Form.Field>
  );
}
