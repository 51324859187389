import { MutationUpdaterFn } from 'apollo-client';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import CURRENT_USER_QUERY from 'graphql/queries/currentUser.graphql';
import socket from 'socket';

interface MutationData {
  token?: string;
  errors?: string;
  currentUser?: User;
  semiLoggedInUser?: SemiLoggedInUser;
}

const updateApolloCacheAfterLogin = (
  cache: Parameters<MutationUpdaterFn<any>>[0],
  data: MutationData
) => {
  if (!data || !data.token || (!data.currentUser && !data.semiLoggedInUser)) {
    return;
  }

  const currentQuery: {
    currentUser: User | null;
    semiLoggedInUser: SemiLoggedInUser | null;
  } | null = cache.readQuery({
    query: CURRENT_USER_QUERY,
  });

  if (!currentQuery) return;

  if (data.currentUser) {
    cache.writeQuery({
      query: CURRENT_USER_QUERY,
      data: {
        ...currentQuery,
        currentUser: {
          ...currentQuery.currentUser,
          ...data.currentUser,
        },
      },
    });

    window.Socialie.tempUserToken = undefined;
    window.localStorage.setItem('satellizer_token', data.token);
  } else {
    cache.writeQuery({
      query: CURRENT_USER_QUERY,
      data: {
        ...currentQuery,
        semiLoggedInUser: {
          ...currentQuery.semiLoggedInUser,
          ...data.semiLoggedInUser,
        },
      },
    });

    window.localStorage.removeItem('satellizer_token');
    window.Socialie.tempUserToken = data.token;
  }

  socket.close();
  socket.connect();
};

export default updateApolloCacheAfterLogin;
