import React from 'react';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import { Form } from 'components';
import { useIntl } from 'hooks';

interface Props {
  filter: MediaLibraryFilter;
  updateFilter: (changes: Partial<MediaLibraryFilter>) => void;
}

export default function MediaAppearancesOnlyFilter(props: Props) {
  const { t } = useIntl();

  const { filter, updateFilter } = props;

  const label = t('MediaLibraryMediaAppearancesOnlyFilter__Label');
  const handleChange = () => {
    if (filter.mediaAppearancesOnly) {
      updateFilter({ mediaAppearancesOnly: null });
    } else {
      updateFilter({ mediaAppearancesOnly: true });
    }
  };

  return (
    <label className="mt-1 flex items-center">
      <div className="mr-0.5">
        <Form.Checkbox
          checked={!!filter.mediaAppearancesOnly}
          onChange={handleChange}
        />
      </div>
      <span className="text-13 leading-tight">{label}</span>
    </label>
  );
}
