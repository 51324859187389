#import '../fragments/SuggestionFields.graphql'
#import '../fragments/SuggestionIntendedRecipients.graphql'

mutation AddPhotoCreditsToCaptions($ids: [ID!]!) {
  addPhotoCreditsToCaptions(ids: $ids) {
    errors
    suggestions {
      ...SuggestionFields
      ...SuggestionIntendedRecipients
    }
  }
}
