mutation RemovePublisherTagFromPublisher(
  $publisherIds: [ID!]!
  $tagIds: [ID!]!
) {
  removePublisherTagFromPublisher(
    tagIds: $tagIds
    publisherIds: $publisherIds
  ) {
    publishers {
      id
      tags {
        id
        name
      }
    }
  }
}
