import React from 'react';
import styled from 'styled-components';
import { RoundSubNav } from 'components';
import { FormattedMessage } from 'react-intl';
import { routes } from 'helpers';
import { grid, colors } from 'styles/theme';
import PENDING_BATCH_SUGGESTION_COUNT from 'graphql/queries/PendingBatchSuggestionCount.graphql';
import { useQuery } from '@apollo/react-hooks';

const Badge = styled.div`
  display: flex;
  position: relative;
  top: 1px;
  align-items: center;
  justify-content: center;
  margin-left: ${grid(1)};
  border-radius: 50%;
  background-color: ${colors.blue};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;

  .active & {
    background-color: ${colors.white};
    color: ${colors.socialiePink};
  }

  span {
    position: relative;
    top: -1px;
  }
`;

export default function TrackingSubNavBar() {
  const { data } = useQuery(PENDING_BATCH_SUGGESTION_COUNT, {
    fetchPolicy: 'network-only',
  });

  return (
    <RoundSubNav>
      <RoundSubNav.Item
        to={routes.tracking.index}
        data-qa="tracking-sub-nav-bar-tracking-sub-nav-bar-tracking"
        exact
      >
        <FormattedMessage id="TrackingSubNavBar__Tracking" />
      </RoundSubNav.Item>

      <RoundSubNav.Item
        to={routes.tracking.pending}
        data-qa="tracking-sub-nav-bar-tracking-sub-nav-bar-pending"
        exact
      >
        <div className="flex items-center">
          <FormattedMessage id="TrackingSubNavBar__Pending" />
          {data &&
            data.batchSuggestions &&
            !!data.batchSuggestions.totalCount && (
              <Badge>
                <span>{data.batchSuggestions.totalCount}</span>
              </Badge>
            )}
        </div>
      </RoundSubNav.Item>

      <RoundSubNav.Item
        to={routes.tracking.drafts}
        data-qa="tracking-sub-nav-bar-tracking-sub-nav-bar-drafts"
      >
        <FormattedMessage id="TrackingSubNavBar__Drafts" />
      </RoundSubNav.Item>
    </RoundSubNav>
  );
}
