import { useReducer, useRef } from 'react';
import { createSlice } from 'redux-starter-kit';
import { flashMessage } from 'helpers';
import useDebouncedCallback from 'use-debounce/lib/callback';
import SocialAccount from 'types/SocialAccount';

const SEARCH_SOCIAL_ACCOUNTS = `
  query SearchSocialAccounts($platform: String!, $query: String!) {
    searchSocialAccounts(platform: $platform, query: $query) {
      platformUid
      platform
      image
      username
      name
      followers
      verified
      canDm
      url
    }
  }
`;

const initialState = {
  searchResults: [] as SocialAccount[],
  isSearching: false,
  noResults: false,
  query: '',
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'useSocialAccountSearch',
  reducers: {
    searchStart: (state) => {
      state.isSearching = true;
      state.noResults = false;
      state.searchResults = [];
    },
    searchStop: (state) => {
      state.isSearching = false;
    },
    searchSuccess: (state, action: { payload: SocialAccount[] }) => {
      state.searchResults = action.payload;
      if (!action.payload.length) state.noResults = true;
    },
    searchClear: (state) => {
      state.isSearching = false;
      state.noResults = false;
      state.searchResults = [];
    },
    updateQuery: (state, action: { payload: string }) => {
      state.query = action.payload;
    },
  },
});

interface UseSocialAccountOptions {
  platform: string;
  searchOnType?: boolean;
}

export default function useSocialAccountSearch({
  platform,
  searchOnType,
}: UseSocialAccountOptions) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const controllerRef = useRef<AbortController | null>(null);

  const handleSearch = async (value?: string) => {
    let searchTerm = (value || state.query).trim();
    if (platform !== 'twitter') searchTerm = searchTerm.replace(/^@/, '');
    if (!searchTerm || searchTerm === '@') return;

    dispatch(actions.searchStart());

    const token =
      localStorage.getItem('satellizer_token') || window.Socialie.tempUserToken;

    controllerRef.current = new AbortController();

    try {
      // We're using fetch instead of apollo here because trying to create an
      // abortable apollo query is a frickin nightmare and we don't need the
      // cache for this
      const result = await fetch(
        `${import.meta.env.REACT_APP_API_SERVER_URL}/graphql`,
        {
          signal: controllerRef.current.signal,
          headers: {
            'X-Socialie-Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            query: SEARCH_SOCIAL_ACCOUNTS,
            variables: {
              query: searchTerm,
              platform,
            },
          }),
        }
      );

      if (!result.ok) throw new Error(`${result.status}`);

      const data = await result.json();
      const results = data.data.searchSocialAccounts || [];
      dispatch(actions.searchSuccess(results));
    } catch (e) {
      if (controllerRef.current?.signal.aborted) {
        controllerRef.current = null;
      } else {
        console.error(e);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      }
    } finally {
      dispatch(actions.searchStop());
    }
  };

  const debouncedOnChange = useDebouncedCallback((val: string) => {
    if (!!val.trim()) handleSearch(val);
  }, 500);

  const setQuery = (query: string) => {
    dispatch(actions.updateQuery(query));
    if (!searchOnType) return;

    controllerRef.current?.abort();

    debouncedOnChange(query);
    if (!query.trim()) dispatch(actions.searchClear());
  };

  return {
    ...state,
    handleSearch,
    setQuery,
  };
}
