#import '../fragments/TwilioMessageFields.graphql'

query TwilioMessage($id: ID!) {
  twilioMessage(id: $id) {
    ...TwilioMessageFields
    suggester {
      id
      hasUnreadChatMessages
    }
  }
}
