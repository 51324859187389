import MARK_CHAT_READ from 'graphql/mutations/markChatRead.graphql';
import apolloClient from '../apolloClient';

const markChatRead = async (id: number | string) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: MARK_CHAT_READ,
    variables: { id },
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data && data.markChatRead && data.markChatRead.errors) {
    return { errors: data.markChatRead.errors };
  }
  if (data) return data;
  return null;
};

export default markChatRead;
