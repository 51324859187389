import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Modal } from 'components';
import { acceptFiles } from 'config';
import { useIntl } from 'hooks';
import { IconTag, IconUploadFancy } from 'icons';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  getFileUploadsForMediaLibrary,
  removeUpload,
  startUpload,
} from 'redux/ducks/fileUploads';
import { closeMediaUploadModal, openMediaUploadModal } from 'redux/ducks/ui';
import { useAppSelector } from 'redux/store';
import MediaUploadUploadRow from './MediaUploadUploadRow';
import FileUpload from 'types/FileUpload';
import MediaUploadToast from './MediaUploadToast';
import useDebouncedCallback from 'use-debounce/lib/callback';
import MediaUploadStagedFileRow from './MediaUploadStagedFileRow';
import MediaUploadTagging from './MediaUploadTagging';
import Publisher from 'types/Publisher';
import { cn } from 'helpers/cn';
import generateRandomValue from 'helpers/generateRandomValue';

interface Props {
  presentation: 'modal' | 'embedded';
}

export type CategorizedUploads = {
  [key in
    | 'active'
    | 'processing'
    | 'completed'
    | 'failed'
    | 'queued'
    | 'canceled']: FileUpload[];
};

export default function MediaUpload({ presentation }: Props) {
  const isOpen = useAppSelector((state) => state.ui.isMediaUploadModalOpen);
  const uploads = useAppSelector((state) =>
    getFileUploadsForMediaLibrary(state)
  );
  const [stagedFiles, setStagedFiles] = useState<Record<string, File>>({});
  const [taggings, setTaggings] = useState<
    Record<string, Array<Pick<Publisher, 'id' | 'name' | 'image'>>>
  >({});

  const [currentView, setCurrentView] = useState<'uploads' | 'tagging'>(
    'uploads'
  );
  const stagedFileCount = Object.keys(stagedFiles).length;

  // const [skipAutomations, setSkipAutomations] = useState(false);

  const uploadsByCategory = useMemo(() => {
    const uploadsByCategory: CategorizedUploads = {
      active: [],
      processing: [],
      completed: [],
      failed: [],
      queued: [],
      canceled: [],
    };

    uploads.forEach((upload) => {
      if (upload.attachmentStatus === 'processed') {
        uploadsByCategory.completed.push(upload);
      } else if (upload.status === 'error') {
        uploadsByCategory.failed.push(upload);
      } else if (upload.status === 'canceled') {
        uploadsByCategory.canceled.push(upload);
      } else if (upload.attachmentId) {
        uploadsByCategory.processing.push(upload);
      } else if (upload.status === 'waiting') {
        uploadsByCategory.queued.push(upload);
      } else {
        uploadsByCategory.active.push(upload);
      }
    });

    return uploadsByCategory;
  }, [uploads]);

  const dispatch = useDispatch();
  const { t } = useIntl();

  const dismissCanceledUploads = () => {
    uploadsByCategory.canceled.forEach((upload) => {
      dispatch(removeUpload(upload.id));
    });
  };

  const dismissFinishedUploads = () => {
    [uploadsByCategory.completed, uploadsByCategory.failed].forEach(
      (category) => {
        category.forEach((upload) => {
          dispatch(removeUpload(upload.id));
        });
      }
    );
  };

  const handleSelectFile = async (files: File[]) => {
    if (files.length > 0) {
      let nextFiles: Record<string, File> = {};
      for (const file of files) {
        const uuid = await generateRandomValue();
        nextFiles[uuid] = file;
      }
      setStagedFiles({ ...stagedFiles, ...nextFiles });
    }
  };

  const handleRemoveStagedFile = (id: string) => {
    const { [id]: _, ...nextFiles } = stagedFiles;
    setStagedFiles(nextFiles);
  };

  const handleStartUpload = () => {
    Object.keys(stagedFiles).forEach((key) => {
      const file = stagedFiles[key];
      dispatch(
        startUpload({
          file,
          isMediaLibraryUpload: true,
          previewUrl: URL.createObjectURL(file),
          skipAutomations: false,
          publisherIds: taggings[key]?.map((p) => p.id) || [],
        })
      );
    });
    setStagedFiles({});
  };

  const handleClose = () => {
    setStagedFiles({});
    setCurrentView('uploads');
    dismissCanceledUploads();

    // If there are no remaining active uploads, dismiss finished uploads
    // as the user is done with this "session."
    if (
      [
        uploadsByCategory.processing,
        uploadsByCategory.active,
        uploadsByCategory.queued,
      ].every((category) => category.length === 0)
    ) {
      dismissFinishedUploads();
    }
    dispatch(closeMediaUploadModal());
  };

  const showsFiles = !!stagedFileCount || !!uploads.length;

  const uploadsRemaining =
    uploadsByCategory.active.length +
    uploadsByCategory.queued.length +
    uploadsByCategory.processing.length;

  const isSessionActive = Object.values(uploadsByCategory).some(
    (uploads) => uploads.length > 0
  );

  const onFileRename = (assetId: string, newName: string) => {
    if (newName.trim().length > 0) {
      setStagedFiles((prev) => {
        const next = { ...prev };
        const oldFile = prev[assetId];

        const dotIndex = oldFile.name.lastIndexOf('.');
        const fileExtension = oldFile.name.substring(dotIndex);

        // Determine the new file name
        const newFileName = `${newName}${fileExtension}`;

        next[assetId] = new File([oldFile], newFileName, {
          type: oldFile.type,
          lastModified: oldFile.lastModified,
        });

        return next;
      });
    }
  };

  const internals = (
    <>
      {!uploads.length && currentView === 'uploads' && (
        <Dropzone
          accept={acceptFiles}
          onDrop={handleSelectFile}
          className={clsx(
            'group mb-3 flex w-full origin-top cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-grey6 bg-grey9',
            !showsFiles ? 'h-50' : 'h-12'
          )}
          disabledClassName="Dropzone--is-disabled"
          activeClassName="Dropzone--is-dropping"
          rejectClassName="Dropzone--is-rejecting"
        >
          {!stagedFileCount && (
            <IconUploadFancy className="mb-2 block h-5 w-5" />
          )}

          <div className="text-center text-16 text-dark">
            <FormattedMessage
              id="MediaUpload__UploadText"
              values={{
                browse: (
                  <span className="cursor-pointer text-socialiePink group-hover:underline">
                    {t('MediaUpload__Browse')}
                  </span>
                ),
              }}
            />
          </div>
        </Dropzone>
      )}

      {!!stagedFileCount ? (
        currentView === 'tagging' ? (
          <MediaUploadTagging
            files={stagedFiles}
            taggings={taggings}
            onUpdate={(key, publishers) => {
              setTaggings((prev) => ({ ...prev, [key]: publishers }));
            }}
            onDone={() => setCurrentView('uploads')}
          />
        ) : (
          <ScrollingFileList fileCount={stagedFileCount}>
            {Object.entries(stagedFiles).map(([id, file]) => (
              <MediaUploadStagedFileRow
                key={id}
                assetId={id}
                file={file}
                onRemove={() => handleRemoveStagedFile(id)}
                onFileRename={onFileRename}
              />
            ))}
          </ScrollingFileList>
        )
      ) : !!uploads.length ? (
        <ScrollingFileList fileCount={uploads.length}>
          {uploads.map((upload) => (
            <MediaUploadUploadRow key={upload.id} fileUpload={upload} />
          ))}
        </ScrollingFileList>
      ) : null}

      {/* Temporarily hidden until API in in place */}
      {/* {showsFiles && (
      <div className="border-t-default mb-3 flex items-start justify-between pt-2">
        <div className="pt-[3px]">
          <Form.Checkbox
            checked={skipAutomations}
            disabled={uploads.length > 0}
            onChange={() => setSkipAutomations((v) => !v)}
            id="skip-automations-toggle"
          />
        </div>
        <div className="ml-2 flex-1 text-left">
          <label
            className="leading-24 block text-16 text-dark"
            htmlFor="skip-automations-toggle"
          >
            {t('MediaUpload__SkipAutomations')}
          </label>

          <p className="text-14 leading-tight">
            {t('MediaUpload__SkipAutomationsDescription')}
          </p>
        </div>
      </div>
    )} */}

      {((presentation === 'embedded' &&
        (!isSessionActive || (isSessionActive && !uploadsRemaining))) ||
        (presentation === 'modal' && !uploads.length)) && (
        <Modal.Actions>
          {!uploads.length && !stagedFileCount && presentation === 'modal' && (
            <Button
              inlineBlock
              cancel
              type="button"
              onClick={handleClose}
              data-qa="media-upload-modal-cancel-button"
            >
              <FormattedMessage id="Global__Cancel" />
            </Button>
          )}

          {!uploads.length && currentView === 'uploads' && (
            <>
              {!!stagedFileCount && (
                <Button
                  inlineBlock
                  filledGreyBg
                  type="button"
                  onClick={() => setCurrentView('tagging')}
                >
                  <IconTag />
                  {t('MediaUpload__TagContent')}
                </Button>
              )}

              <Button
                inlineBlock
                filledBg
                disabled={!stagedFileCount}
                onClick={handleStartUpload}
                data-qa="media-upload-modal-upload-button"
              >
                {t('MediaUpload__Upload')}
              </Button>
            </>
          )}

          {isSessionActive && !uploadsRemaining && (
            <Button inlineBlock filledBg onClick={handleClose}>
              {t('Global__Done')}
            </Button>
          )}
        </Modal.Actions>
      )}
    </>
  );

  if (presentation === 'embedded') return internals;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        contentLabel={t('MediaUpload__Heading')}
        renderHeading={t('MediaUpload__Heading')}
        theme={{ large: true }}
      >
        {internals}
      </Modal>

      <MediaUploadToast
        uploadsByCategory={uploadsByCategory}
        uploadsRemaining={uploadsRemaining}
        isModalOpen={isOpen}
        onOpenModal={() => dispatch(openMediaUploadModal())}
        onDismiss={dismissFinishedUploads}
      />
    </>
  );
}

interface ScrollingFileListProps {
  children: React.ReactNode;
  fileCount: number;
  containerClassName?: string;
}

export function ScrollingFileList({
  children,
  fileCount,
  containerClassName,
}: ScrollingFileListProps) {
  const scrollContainer = React.useRef<HTMLDivElement>(null);
  const innerContainer = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  // Check if the inner container is overflowing
  useEffect(() => {
    if (
      scrollContainer.current &&
      innerContainer.current &&
      innerContainer.current.clientHeight > scrollContainer.current.clientHeight
    ) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [fileCount]);

  const debouncedHandleScroll = useDebouncedCallback(() => {
    const el = scrollContainer.current;
    if (!el) return;
    setIsScrolledToBottom(el.scrollHeight - el.scrollTop === el.clientHeight);
  }, 10);

  // Watch for scroll events on the scroll container
  useEffect(() => {
    if (!scrollContainer.current) return;
    scrollContainer.current.addEventListener('scroll', debouncedHandleScroll);
  }, [debouncedHandleScroll]);

  return (
    <div className="relative mb-2 last:mb-0">
      <div
        ref={scrollContainer}
        className={cn(
          'hide-scrollbar relative h-full max-h-44 w-full overflow-auto',
          containerClassName
        )}
      >
        <div ref={innerContainer}>{children}</div>
      </div>
      {isOverflowing && (
        <div
          className={clsx(
            'pointer-events-none absolute bottom-0 left-0 right-0 z-1 h-4 origin-bottom bg-gradient-to-b from-transparent to-white transition-all duration-150',
            isScrolledToBottom && 'scale-y-0 opacity-0'
          )}
          aria-hidden
        />
      )}
    </div>
  );
}
