#import '../fragments/DraftFields.graphql'

mutation UpdateDraft(
  $suggestionIds: [ID!]!
  $platform: String!
  $text: String!
  $mentions: [JSON!]
) {
  updateDraft(
    suggestionIds: $suggestionIds
    platform: $platform
    text: $text
    mentions: $mentions
  ) {
    suggestions {
      id
      updatedAt
      platforms {
        id
      }
      drafts {
        ...DraftFields
      }
    }
  }
}
