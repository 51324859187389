import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import socket from 'socket';
import { useAppSelector } from 'redux/store';
import { getFileUploadsForMediaLibrary } from 'redux/ducks/fileUploads';

interface Props {
  onRefresh: () => void;
  canAutoRefresh?: boolean;
}

interface Payload {
  attachmentIds?: number[];
}

// prettier-ignore
const Wrapper = styled.button.attrs({ type: 'button' })`
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform 0.15s, opacity 0.15s;
  opacity: 0;
  border-radius: 12px;
  background-color: ${colors.socialiePink};
  padding: 0 ${grid(1)} ${grid(0.25)};
  color: ${colors.white};
  font-size: 12px;
  font-weight: bold;

  ${(props: any) => props.hasNewContent && `
    transform: scale(1);
    opacity: 1;
  `};

   > span {
    display: inline-block;
    margin-right: ${grid(0.5)};
    border-radius: 50%;
    background: ${colors.blue};
    width: ${grid(1)};
    height: ${grid(1)};
   }
` as any;

export default function NewContentNotification(props: Props) {
  const { onRefresh, canAutoRefresh = false } = props;
  const [hasNewContent, setHasNewContent] = useState(false);

  // If we can auto-refresh and the user is at the top of the page we can
  // just refresh the content. Otherwise we show the "new content" notification.
  const handleNewContent = useCallback(() => {
    if (canAutoRefresh && window.scrollY < 100) {
      onRefresh();
    } else {
      setHasNewContent(true);
    }
  }, [canAutoRefresh, onRefresh]);

  useEffect(() => {
    const handleSocketMsg = (payload: Payload) => {
      if (!payload.attachmentIds || !payload.attachmentIds.length) return;
      handleNewContent();
    };

    socket.on('mediaImportCompleted', handleSocketMsg);
    return () => {
      socket.removeListener('mediaImportCompleted', handleSocketMsg);
    };
  }, [handleNewContent]);

  // Get the media library uploads from the redux store
  const mediaLibraryUploads = useAppSelector((state) =>
    getFileUploadsForMediaLibrary(state)
  );

  // Get the IDs of the uploads that have been completed
  const completedUploadIds = useMemo(() => {
    return mediaLibraryUploads
      .filter((upload) => upload.attachmentStatus === 'processed')
      .map((upload) => upload.id);
  }, [mediaLibraryUploads]);

  // Keep track of the uploads that were already completed when the component
  // was first rendered. These are the uploads that were already visible to the
  // user when they first opened the media library, so we don't want to show
  // the "new content" notification for them.
  const seenUploadIds = useRef(completedUploadIds);

  // If the completedUploadIds array changes, check if there are any new
  // completed uploads that weren't already visible to the user when they first
  // opened the media library.
  useEffect(() => {
    if (!seenUploadIds.current) return;
    const newUploads = completedUploadIds.filter(
      (id) => !seenUploadIds.current.includes(id)
    );
    if (!newUploads.length) return;
    seenUploadIds.current = completedUploadIds;
    handleNewContent();
  }, [completedUploadIds, handleNewContent]);

  const handleClick = () => {
    onRefresh();
    window.scrollTo(0, 0);
    setHasNewContent(false);
  };

  return (
    <Wrapper hasNewContent={hasNewContent} onClick={handleClick}>
      <span />
      Click for new content
    </Wrapper>
  );
}
