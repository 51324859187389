#import '../fragments/AccountConfigurationFields.graphql'

mutation UpdateAccountConfiguration(
  $id: ID!
  $values: AccountConfigurationInput!
) {
  updateAccountConfiguration(id: $id, values: $values) {
    errors
    accountConfiguration {
      ...AccountConfigurationFields
    }
  }
}
