#import "../fragments/CurrentUserFields.graphql"
#import "../fragments/SemiLoggedInUserFields.graphql"

mutation LogIn($email: String!, $password: String!) {
  logIn(email: $email, password: $password) {
    token
    errors

    currentUser {
      ...CurrentUserFields
    }

    semiLoggedInUser {
      ...SemiLoggedInUserFields
    }
  }
}
