#import './SuggesterFields.graphql'

fragment CurrentUserFields on User {
  id
  authyId
  enableMfa
  currentRole
  mfaPhone
  mfaProtocol
  email
  phone
  name
  firstName
  lastName
  createdAt
  twitterUsername
  twitterUserId
  publisherRedirectUrl
  currentSuggester {
    ...SuggesterFields
    viewerCanInvite
    viewerCanManageCustomer
    viewerCanEditMfa
    viewerCanChat
    hasChat
    hasUnreadChatMessages
    twilioNumbers {
      id
      countryCode
      phone
    }
    twilioPurchasedPrefixes
    facebookPage {
      id
      instagramBusinessId
      invalidToken
    }
    onboardingCompleted
    children {
      viewerIsMember
      ...SuggesterFields
    }
    parent {
      ...SuggesterFields
    }
    oauthIdentities {
      id
      provider
      name
      username
      image
      invalidPermissions
      invalidToken
    }
  }
  suggesters {
    id
    viewerIsMember
    accountName
    brand
    accountConfiguration {
      id
      logoUrl
    }
    children {
      id
      viewerIsMember
      accountName
      brand
      accountConfiguration {
        id
        logoUrl
      }
    }
  }
  oauthIdentities {
    id
    provider
    name
    username
    image
    invalidPermissions
    invalidToken
  }
}
