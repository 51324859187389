#import './AttachmentFields.graphql'
#import './StoryInsightFields.graphql'

fragment RequestFields on Request {
  id
  completedAt
  lastCompletedAt
  declineReason
  deleted
  downloadedAt
  openedAt
  sentAt
  status
  updatedAt
  url
  submittedAttachments {
    ...AttachmentFields
  }
  deliveryReceipts {
    id
    to
    from
    protocol
    status
    errorMessage
    sentAt
  }
  posts {
    id
    platform
    status
    statusMessage
    postAt
    url
  }
  publisher {
    id
    name
    image
    disabled
    identities {
      id
      platform
    }
    suggester {
      id
    }
  }
  stories {
    id
    platform
    text
    url
    publishedAt
    reach
    expiredAt
    lastInsight {
      ...StoryInsightFields
    }
    storyInsights {
      ...StoryInsightFields
    }
  }
}
