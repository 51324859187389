#import './RecipientPublisherFields.graphql'

fragment RecipientContactMethodFields on ContactMethod {
  id
  protocol
  name
  value
  wantsNotifications
  doNotCall
  publisher {
    ...RecipientPublisherFields
  }
}
