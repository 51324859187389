import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { startUpload, cancelUpload } from 'redux/ducks/fileUploads';
import { EmojiPicker } from 'components';
import { grid, colors } from 'styles/theme';
import { IconMediaLibrary } from 'icons';
import FileUpload from 'types/FileUpload';
import StartUploadParams from 'types/StartUploadParams';
import { Field, SendButton } from './components';

interface OwnProps {
  chatId: string;
  value: string;
  fileUpload?: FileUpload;
  onChange: (val: string) => void;
  onSend: () => void;
  autoFocus?: boolean;
  fieldDisabled?: boolean;
  sendDisabled?: boolean;
  isThreadExpired: boolean;
}

interface ConnectedActions {
  startUpload: (params: StartUploadParams) => void;
  cancelUpload: (uploadId: string) => void;
}

type Props = OwnProps & ConnectedActions;

const Actions = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 ${grid(2)};
`;

const ToolsWrapper = styled.div`
  display: flex;
  position: relative;
  top: -1px;
  align-items: center;

  > * {
    margin-right: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ImagePicker = styled.div`
  width: ${grid(2.5)};
  height: ${grid(2.5)};

  input {
    position: absolute;
    top: 0;
    left: -99999px;
  }

  button {
    position: relative;
    top: ${grid(0.25)};
    width: ${grid(2.5)};
    height: ${grid(2.5)};
    color: ${colors.lightText};

    &:disabled {
      opacity: 0.4;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;

class MessageForm extends React.Component<Props> {
  private textareaEl?: HTMLTextAreaElement;
  private fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  onSelectEmoji = (emoji: any) => {
    this.props.onChange(`${this.props.value}${emoji.native}`);
    this.textareaEl && this.textareaEl.focus();
  };

  onClickFileInput = () =>
    this.fileInputRef.current && this.fileInputRef.current.click();

  onStartUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
    this.props.startUpload({
      file,
      previewUrl: URL.createObjectURL(file),
      chatId: this.props.chatId,
      mmsMessage: true,
    });
  };

  onCancelUpload = () => {
    if (!this.props.fileUpload) return;
    this.props.cancelUpload(this.props.fileUpload.id);
    if (this.fileInputRef.current) this.fileInputRef.current.value = '';
  };

  render() {
    return (
      <>
        <Field
          innerRef={(el) => (this.textareaEl = el)}
          value={this.props.value}
          fileUpload={this.props.fileUpload}
          onChange={this.props.onChange}
          disabled={this.props.isThreadExpired || this.props.fieldDisabled}
          onSend={this.props.sendDisabled ? () => {} : this.props.onSend}
          onCancelUpload={this.onCancelUpload}
          autoFocus={this.props.autoFocus}
        />

        <Actions>
          <SendButton
            onSend={this.props.onSend}
            disabled={!!this.props.isThreadExpired || this.props.sendDisabled}
            fileUpload={this.props.fileUpload}
          />

          <ToolsWrapper>
            <EmojiPicker onSelect={this.onSelectEmoji} />

            <ImagePicker>
              <input
                ref={this.fileInputRef}
                type="file"
                accept="image/jpeg,image/gif,image/png"
                onChange={this.onStartUpload}
              />
              <button
                disabled={!!this.props.fileUpload}
                onClick={this.onClickFileInput}
              >
                <IconMediaLibrary />
              </button>
            </ImagePicker>
          </ToolsWrapper>
        </Actions>
      </>
    );
  }
}

const mapDispatchToProps = { startUpload, cancelUpload };

export default connect<void, ConnectedActions, OwnProps>(
  null,
  mapDispatchToProps
)(MessageForm);
