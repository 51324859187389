import React, { useRef, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors, grid } from 'styles/theme';
import { IconClose } from 'icons';
import ModalAdapter from '../ModalAdapter';
import { mixins } from 'styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface Props {
  isOpen: boolean;
  onRequestClose?(): void;
  theme?: {
    small?: boolean;
    medium?: boolean;
    large?: boolean;
    xlarge?: boolean;
    media?: boolean;
    deleteTagsModal?: boolean;
    noPadding?: boolean;
    photoEditModal?: boolean;
    hideClose?: boolean;
    noBorder?: boolean;
  };
  contentLabel: string;
  children: any;
  renderHeading?: React.ReactNode;
}

// prettier-ignore
const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  style: {
    overlay: {
      background: 'rgba(28, 37, 47, 0.8)',
    },
  },
})`
  .Modal {
    transition: opacity 0.15s;
    opacity: 0;
    outline: none;
  }

  .Modal,
  .Modal__Closer {
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;

    width: 100vw;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .Modal__Scroller {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: ${grid(3)};
    overflow: visible;
  }

  .Modal__Contents {
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.154);
    background: ${colors.white};
    width: ${grid(50)};
    max-width: 90%;
    overflow: visible;
    text-align: center;

    ${({ theme }: any) => theme.xlarge && `width: ${grid(110)}`};
    ${({ theme }: any) => theme.large && `width: ${grid(75)}`};
    ${({ theme }: any) => theme.medium && `width: ${grid(62.5)}`};
    ${({ theme }: any) => theme.photoEditModal && `width: ${grid(56.25)}`};
    ${({ theme }: any) => theme.deleteTagsModal && `width: ${grid(52)}`};
    ${({ theme }: any) => theme.small && `width: ${grid(42.5)}`};
    ${({ theme }: any) =>
      theme.media &&
      `
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  }

  .Modal--after-open {
    opacity: 1;
  }

  .Modal--before-close {
    transition: opacity 0s;
    opacity: 0;
  }

  ${mixins.modal};
` as any;

const Heading = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.border};
  ${({ theme }: any) => theme.noBorder && 'border: none'};
  padding: ${grid(2)} ${grid(3)};
  ${({ theme }: any) => theme.photoEditModal && 'padding: 16px'};
  color: ${colors.darkText};
  font-size: 18px;
  text-align: left;
`;

const Content = styled.div`
  padding: ${grid(3)};
  ${({ theme }: any) => theme.noPadding && 'padding: 0'};
  ${({ theme }: any) => theme.media && 'padding: 0'};
`;

const CloseButton = styled.div`
  transition: color 0.15s;
  margin-left: auto;
  padding-top: ${grid(0.5)};
  cursor: pointer;
  color: ${colors.darkText};

  &:hover {
    color: ${colors.bodyText};
  }

  svg {
    display: block;
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: ${grid(1)};
    min-width: ${grid(10)};
  }
`;

export default function Modal(props: Props) {
  const handleClose = () => {
    props.onRequestClose && props.onRequestClose();
  };

  return (
    <ThemeProvider theme={props.theme || {}}>
      <StyledModal
        appElement={document.body}
        isOpen={props.isOpen}
        contentLabel={props.contentLabel}
        onRequestClose={handleClose}
        closeTimeoutMS={0}
      >
        <ModalInnards onRequestClose={handleClose}>
          {props.renderHeading && (
            <Heading>
              {props.renderHeading}
              {!props.theme?.hideClose && (
                <CloseButton data-qa="modal-close-icon" onClick={handleClose}>
                  <IconClose />
                </CloseButton>
              )}
            </Heading>
          )}

          <Content>{props.children}</Content>
        </ModalInnards>
      </StyledModal>
    </ThemeProvider>
  );
}

Modal.Actions = Actions;

interface ModalInnardsProps {
  onRequestClose: Props['onRequestClose'];
  children: React.ReactNode;
}

function ModalInnards(props: ModalInnardsProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentEl = contentRef.current;
    if (currentEl) disableBodyScroll(currentEl, { reserveScrollBarGap: true });

    return () => {
      if (currentEl) enableBodyScroll(currentEl);
    };
  }, []);

  return (
    <div
      className="Modal__Closer"
      ref={contentRef}
      onClick={props.onRequestClose}
    >
      <div className="Modal__Scroller">
        <div className="Modal__Contents" onClick={(e) => e.stopPropagation()}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
