#import '../fragments/RequestFields.graphql'

mutation ResendSuggestion($id: ID!) {
  resendSuggestion(id: $id) {
    errors
    suggestion {
      id
      requests {
        ...RequestFields
      }
    }
  }
}
