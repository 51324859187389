export default async function generateRandomValue(): Promise<string> {
  if (
    typeof crypto !== 'undefined' &&
    typeof crypto.randomUUID === 'function'
  ) {
    return crypto.randomUUID();
  }

  const { v4 } = await import('uuid');
  return v4();
}
