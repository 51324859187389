query MediaLibraryFilterData {
  currentSuggester {
    id
    attachmentSources
    uploaders {
      id
      name
    }
    accountConfiguration {
      id
      sentimentTagCreditsRemaining
    }
  }

  suggestionAlbums {
    id
    name
  }
}
