import { useEffect } from 'react';
import includes from 'lodash/includes';
import socket from 'socket';
import query from 'graphql/queries/attachmentsById.graphql';
import { useLazyQuery } from 'react-apollo';

/**
 * Listen and react to the attachmentsUpdated socket event in a component
 * @param {number} [attachmentId] A specific attachment id to watch for
 * @param {function} [operation] A custom operation to run instead of the default
 */
export default function useListenForAttachmentUpdates(
  attachmentId?: number,
  operation?: (ids: number[]) => any
) {
  const [loadAttachments] = useLazyQuery(query);

  useEffect(() => {
    const handleAttachmentsUpdated = async ({ attachmentIds }: any) => {
      if (!attachmentId || includes(attachmentIds, attachmentId)) {
        if (operation) {
          operation(attachmentIds);
        } else {
          loadAttachments({ variables: { ids: attachmentIds } });
        }
      }
    };

    socket.on('attachmentsUpdated', handleAttachmentsUpdated);

    return () => {
      socket.removeListener('attachmentsUpdated', handleAttachmentsUpdated);
    };
  }, [attachmentId, operation, loadAttachments]);
}
