query Tags {
  tags {
    edges {
      node {
        id
        name
        suggestionsCount
        createdAt
      }
    }
  }
}
