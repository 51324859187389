fragment FaceFields on Face {
  id
  width
  height
  top
  left
  mediaAppearance {
    id
    publisher {
      id
      name
    }
  }
}
