import { useContext, useRef } from 'react';
import React from 'react';
const rainbowColors = [
  'rgba(252, 35, 97, 1)',
  'rgba(134, 118, 255, 1)',
  'rgba(255, 186, 105, 1)',
  'rgba(0, 139, 248, 1)',
  'rgba(78, 198, 176, 1)',
  'rgba(145, 152, 161, 1)',
];

const RainbowContext = React.createContext<{
  getNextColor: () => string | null;
}>({
  getNextColor: () => null,
});

export const RainbowGroup: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Start from a random color
  const currentIndexRef = useRef(
    Math.floor(Math.random() * rainbowColors.length)
  );

  const getNextColor = () => {
    const color = rainbowColors[currentIndexRef.current];
    currentIndexRef.current =
      (currentIndexRef.current + 1) % rainbowColors.length;
    return color;
  };

  return (
    <RainbowContext.Provider value={{ getNextColor }}>
      {children}
    </RainbowContext.Provider>
  );
};

export const useRainbowColor = ({ skip = false }: { skip?: boolean } = {}) => {
  const { getNextColor } = useContext(RainbowContext);
  const colorRef = useRef<string | null>(null);

  if (!skip && colorRef.current === null) {
    colorRef.current = getNextColor();
  }

  return skip ? null : colorRef.current;
};
