fragment AttachmentFields on Attachment {
  id
  deleted
  status
  automationStatus
  blurThumbnail: thumbnail(size: "tiny")
  thumbnail(size: "medium")
  mediaUrl
  mediaContentType
  mediaFilename
  mediaSize
  type
  notes
  aspectRatio
  width
  height
  length
  source
  sourceId
  createdAt
  engagement
  reach
  title
  caption
  aiMetadata
  keywords
  photographer
  credit
  location
  peopleInThisMedia
  canMonetize
}
