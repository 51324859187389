import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cn } from 'helpers/cn';

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>,
  'content'
> & {
  content: React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const Popover = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      asChild = true,
      align = 'center',
      sideOffset = 4,
      children,
      content,
      isOpen,
      onOpenChange,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <PopoverPrimitive.Trigger asChild={true}>
        {children}
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(
            'border-default z-[9999] w-36 rounded-md bg-white p-2 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className
          )}
          {...props}
        >
          {content}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  )
);

Popover.displayName = PopoverPrimitive.Content.displayName;

export default Popover;
