import React from 'react';
import {
  Form,
  MediaSourceFilter,
  Loader,
  SuggesterFilter,
  PublisherFilter,
  SavedFilterSets,
  QueryFilter,
} from 'components';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import {
  MediaTypeFilter,
  SuggestedFilter,
  MediaAppearancesOnlyFilter,
  AlbumFilter,
  CanMonetizeFilter,
  MediaUploadersFilter,
  MediaSentimentTagsFilter,
} from './components';
import { CreatedAtFilter } from 'screens/MediaLibrary/components';
import MediaLibraryFilterPills from '../MediaLibraryFilterPills';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';
import PublisherAndPublisherTagFilter from 'components/PublisherAndPublisherTagFilter';

interface Props {
  loading?: boolean;
  data?: MediaLibraryFilterData;
  isAttachmentAlbumScreen?: boolean;
  defaultFilter: MediaLibraryFilter;
  filter: MediaLibraryFilter;
  isFiltered: boolean;
  updateFilter: (updates: Partial<MediaLibraryFilter>) => void;
}

const MediaLibraryFilters = (props: Props) => {
  const {
    loading,
    data,
    filter,
    updateFilter,
    isAttachmentAlbumScreen = false,
    defaultFilter,
    isFiltered,
  } = props;
  return (
    <>
      {loading || !data || !data.currentSuggester ? (
        <Loader delay={5000} />
      ) : isAttachmentAlbumScreen ? (
        <Form>
          <MediaSourceFilter
            attachmentSources={data.currentSuggester.attachmentSources}
            value={filter.sources}
            onChange={(val) => {
              const updates: Partial<MediaLibraryFilter> = { sources: val };
              // Clear uploaders if socialie is not selected
              if (!val.includes('socialie')) updates.uploaders = [];
              updateFilter(updates);
            }}
          />

          {filter.sources.includes('socialie') && (
            <MediaUploadersFilter
              uploaders={data.currentSuggester.uploaders}
              value={filter.uploaders}
              onChange={(val) => updateFilter({ uploaders: val })}
            />
          )}
          <hr />

          {data.currentSuggester?.accountConfiguration
            ?.sentimentTagCreditsRemaining > 0 ? (
            <>
              <MediaSentimentTagsFilter
                value={filter.sentimentTags}
                onChange={(val) => updateFilter({ sentimentTags: val })}
              />
              <hr />
            </>
          ) : null}

          <MediaTypeFilter
            value={filter.mediaType}
            onChange={(val) => updateFilter({ mediaType: val })}
          />
          <hr />
          <PublisherAndPublisherTagFilter
            label="MediaLibraryPublisherFilter__Label"
            publisherFilterKey="publishers"
            publisherTagFilterKey="publisherTags"
            updateFilter={updateFilter}
            filter={filter}
          >
            <MediaAppearancesOnlyFilter
              filter={filter}
              updateFilter={updateFilter}
            />
          </PublisherAndPublisherTagFilter>
          <hr />
          <CreatedAtFilter filter={filter} updateFilter={updateFilter} />
        </Form>
      ) : (
        <SavedFilterSets
          filterContext="attachments"
          isFiltered={isFiltered}
          defaultFilter={defaultFilter}
          filter={filter}
          updateFilter={updateFilter}
          renderFilterPills={(filter) => (
            <MediaLibraryFilterPills
              loading={false}
              filterData={data}
              filter={filter as MediaLibraryFilter}
              updateFilter={updateFilter}
              isFiltered
              defaultFilter={defaultFilter}
            />
          )}
        >
          <Form>
            <QueryFilter filter={filter} updateFilter={updateFilter} />

            <SuggestedFilter />

            <hr />

            <MediaSourceFilter
              attachmentSources={data.currentSuggester.attachmentSources}
              value={filter.sources}
              onChange={(val) => {
                const updates: Partial<MediaLibraryFilter> = { sources: val };
                // Clear uploaders if socialie is not selected
                if (!val.includes('socialie')) updates.uploaders = [];
                updateFilter(updates);
              }}
            />

            {filter.sources.includes('socialie') && (
              <MediaUploadersFilter
                uploaders={data.currentSuggester.uploaders}
                value={filter.uploaders}
                onChange={(val) => updateFilter({ uploaders: val })}
              />
            )}

            <hr />

            {data.currentSuggester?.accountConfiguration
              ?.sentimentTagCreditsRemaining > 0 ? (
              <>
                <MediaSentimentTagsFilter
                  value={filter.sentimentTags}
                  onChange={(val) => updateFilter({ sentimentTags: val })}
                />
                <hr />
              </>
            ) : null}

            <MediaTypeFilter
              value={filter.mediaType}
              onChange={(val) => updateFilter({ mediaType: val })}
            />

            <hr />

            <SuggesterFilter
              label="MediaLibrarySuggesterFilter__Label"
              filterKey="suggesters"
              filter={filter}
              updateFilter={updateFilter}
            />

            <PublisherAndPublisherTagFilter
              label="MediaLibraryPublisherFilter__Label"
              publisherFilterKey="publishers"
              publisherTagFilterKey="publisherTags"
              updateFilter={updateFilter}
              filter={filter}
            >
              <MediaAppearancesOnlyFilter
                filter={filter}
                updateFilter={updateFilter}
              />
            </PublisherAndPublisherTagFilter>

            <hr />

            <SuggesterFilter
              label="MediaLibrarySuggesterPublisherFilter__Label"
              filterKey="suggesterPublishers"
              filter={filter}
              updateFilter={updateFilter}
            />

            <PublisherFilter
              label="MediaLibrarySubmittersFilter__Label"
              filterKey="submitters"
              filter={filter}
              updateFilter={updateFilter}
            />

            <hr />

            <CreatedAtFilter filter={filter} updateFilter={updateFilter} />

            {filter.suggested !== '0' && (
              <>
                <hr />

                <PublisherFilter
                  label="MediaLibraryRecipientsFilter__Label"
                  filterKey="suggestionRecipients"
                  filter={filter}
                  updateFilter={updateFilter}
                />

                <hr />

                <AlbumFilter
                  albums={data?.suggestionAlbums || []}
                  value={filter.suggestionAlbums}
                  onChange={(val: (number | string)[]) =>
                    updateFilter({ suggestionAlbums: val })
                  }
                />
              </>
            )}

            <hr />

            <CanMonetizeFilter filter={filter} updateFilter={updateFilter} />
          </Form>
        </SavedFilterSets>
      )}
    </>
  );
};
export default MediaLibraryFilters;
