query Chat($id: ID!) {
  chat(id: $id) {
    id
    phoneNumber
    contactMethods {
      id
      value
    }
    publishers {
      id
      name
      image
      online
    }
  }
}
