#import '../fragments/SuggestionFields.graphql'
#import '../fragments/SuggestionIntendedRecipients.graphql'

mutation AddSuggestionsToExistingBatch($attachmentIds: [ID!], $id: ID) {
  addSuggestionsToBatch(attachmentIds: $attachmentIds, id: $id) {
    errors
    batchSuggestion {
      id
      suggestions {
        ...SuggestionFields
        ...SuggestionIntendedRecipients
      }
    }
    suggestions {
      ...SuggestionFields
      ...SuggestionIntendedRecipients
    }
  }
}
