fragment AttachmentSuggestionFields on Suggestion {
  id
  source
  batchSuggestion {
    id
    sendAt
  }
  contentRoutingRuleSuggestions {
    id
    label
    integrationType
    contentRoutingRule {
      id
      label
      action
      contentIntegration {
        id
        label
        integrationType
      }
    }
  }
  requests {
    id
    status
    sentAt
    completedAt
    updatedAt
    suggestion {
      id
    }
    publisher {
      id
      name
      image
    }
  }
  intendedRecipients {
    id
    contactMethod {
      id
      publisher {
        id
        name
      }
    }
    publisherUser {
      id
      publisher {
        id
        name
      }
    }
    publisherTag {
      id
      name
      publishersCount
    }
    publisher {
      id
      name
    }
  }
}
