import React from 'react';
import Attachment from 'types/Attachment';
import InfoCard from './InfoCard';
import { useIntl } from 'hooks';
import ContentRoutingRuleJoinList from './ContentRoutingRuleJoinList';
import { IconHourglass } from 'icons';

interface Props {
  attachment: Attachment;
}

export default function ContentRoutingRules({ attachment }: Props) {
  const { t } = useIntl();
  if (
    !attachment.contentRoutingRuleAttachments?.length &&
    attachment.automationStatus !== 'processing'
  )
    return null;

  return (
    <InfoCard title={t('AttachmentModal__ContentRoutingRules')}>
      {!!attachment.contentRoutingRuleAttachments?.length ? (
        <ContentRoutingRuleJoinList
          joins={attachment.contentRoutingRuleAttachments}
        />
      ) : (
        <span className="flex items-center gap-0.5">
          <IconHourglass className="h-2 w-2 text-dark" />
          <span className="bump-up-1">{t('AttachmentModal__Processing')}</span>
        </span>
      )}
    </InfoCard>
  );
}
