#import '../fragments/SuggestionFields.graphql'

query SuggestionsById($ids: [ID!], $first: Int) {
  suggestions(first: $first, ids: $ids) {
    edges {
      node {
        ...SuggestionFields
      }
    }
  }
}
