import User from 'types/User';
import { useQuery } from '@apollo/react-hooks';
import CURRENT_USER from 'graphql/queries/currentUser.graphql';

const defaultOptions = { allowNull: false };

export default function useCurrentUser(providedOptions = {}) {
  const options = { ...defaultOptions, ...providedOptions };
  const { data } = useQuery<{ currentUser: User }>(CURRENT_USER, {
    fetchPolicy: 'cache-first',
  });

  if (!data || (!data.currentUser && !options.allowNull)) {
    throw new Error(
      'currentUser is not present in cache, do not useCurrentUser unless you know currentUser is present'
    );
  }

  return data && data.currentUser;
}
