import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIntl } from 'hooks';
import { IconLoader } from 'icons';
import SCAN_ATTACHMENT_FOR_FACES from 'graphql/mutations/scanAttachmentForFaces.graphql';

export default function ScanForFaces({ id }: { id: number }) {
  const { t } = useIntl();
  const [scanForFaces, { loading }] = useMutation(SCAN_ATTACHMENT_FOR_FACES, {
    variables: { id },
  });
  return (
    <div className="absolute bottom-0 right-0 p-1">
      <button
        onClick={() => scanForFaces()}
        className="rounded-lg bg-socialieBlue px-1 text-sm text-white shadow disabled:opacity-50"
        disabled={loading}
      >
        <span className="flex items-center">
          {loading && (
            <IconLoader className="mr-0.5 inline-block h-1.5 w-1.5" />
          )}
          {t('Lightbox__ScanForFaces')}
        </span>
      </button>
    </div>
  );
}
