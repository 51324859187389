import { default as twitter_dm } from './twitter.svg?react';
import { default as sms } from './cellphone.svg?react';
import { default as slack } from './slack.svg?react';
import { default as email } from './email.svg?react';
import { default as whats_app } from './whats-app.svg?react';
import { default as apns } from './apple.svg?react';

const contactMethodIcons = { twitter_dm, sms, slack, email, whats_app, apns };

export default contactMethodIcons;
