fragment AttachmentDetailFields on Attachment {
  largeThumbnail: thumbnail(size: "large")
  contentIntegrationPath
  viewerCanDestroy
  viewerCanEdit
  mediaAppearances {
    id
    publisherFaceFound
    publisherNameFound
    sentimentTag
    publisher {
      id
      name
      image
    }
  }
  publisher {
    id
    name
    image
  }
  request {
    id
    publisher {
      id
    }
    suggestion {
      id
      actionableType
    }
  }
  contentRoutingRuleAttachments {
    id
    label
    integrationType
    contentRoutingRule {
      id
      label
      action
      contentIntegration {
        id
        label
        integrationType
      }
    }
  }
  uploader {
    id
    name
  }
  aiKeywords
  aiStatus
}
