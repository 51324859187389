fragment TwilioMessageFields on TwilioMessage {
  id
  text
  status
  errorCode
  recipientType
  senderType
  publisherPhoneNumber
  createdAt
  readBySuggester
  sender {
    ... on User {
      id
      name
    }
    ... on Publisher {
      id
      name
      image
      online
    }
  }
  suggester {
    id
    accountName
    accountConfiguration {
      id
      logoUrl
    }
  }
  files {
    id
    url
    contentType
  }
  attachments {
    id
    mediaUrl
    mediaContentType
  }
  suggestions {
    id
  }
}
