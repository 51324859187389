import React, { useRef, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { BlurUp } from '..';
import ModalAdapter from '../ModalAdapter';
import Attachment from 'types/Attachment';
import { grid, colors } from 'styles/theme';
import { mixins, media } from 'styles';
import { IconClose } from 'icons';
import closeIconSvg from 'images/close-white.svg';
import { LightboxArrow, LightboxMetadata } from './components';
import Faces from 'components/Faces';
import { useIntl, useDebouncedWindowDimensions, useIsMobile } from 'hooks';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  attachments: Attachment[];
}

// prettier-ignore
const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
  modalClassName: {
    base: 'Lightbox',
    afterOpen: 'Lightbox--after-open',
    beforeClose: 'Lightbox--before-close',
  },
})`
  .Lightbox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    ${media.tablet`
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: calc(100vw - ${grid(24)});
      max-height: calc(100vh - ${grid(24)});
    `}

    transition: opacity 0.15s;
    opacity: 0;
    outline: none;
    overflow: visible;
    text-align: center;
  }

  .Lightbox--after-open {
    opacity: 1;
  }

  .Lightbox--before-close {
    transition: all 0.5s;
    opacity: 0;
  }

  ${mixins.modal};

  .Overlay {
    ${media.tablet`
      background-image: url('${closeIconSvg}');
      background-position: top ${grid(1)} right ${grid(2)};
      background-size: ${grid(3)} ${grid(3)};
      background-repeat: no-repeat;
    `}
  }
` as any;

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 4px;
  padding: ${grid(2)};
  width: 100%;
  height: 100%;

  ${(props: any) => props.hasMetaData && `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: ${colors.grey1};
  `};

  ${media.tablet`
    padding: 0;
  `}
` as any;

// prettier-ignore
const BlurUpWrapper = styled.div`
  position: relative;

  > video,
  > img {
    outline: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

` as any;

const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: ${grid(1)};
  right: ${grid(1)};
  z-index: 10;
  width: ${grid(2.5)};
  height: ${grid(2.5)};
  color: ${colors.white};

  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(1px 1px 2px ${rgba(colors.black, 0.6)});
  }

  ${media.tablet`
    display: none;
  `};
`;

export default function Lightbox(props: Props) {
  const { attachments, isOpen, onRequestClose } = props;
  const { t } = useIntl();
  const [currentAttachmentIdx, setCurrentAttachmentIdx] = useState(0);
  const videoEl = useRef<HTMLVideoElement>(null);
  const attachment = attachments[currentAttachmentIdx];

  const viewNext = useCallback(() => {
    if (videoEl.current) videoEl.current.pause();
    setCurrentAttachmentIdx((prevIdx) => {
      return prevIdx === attachments.length - 1 ? 0 : prevIdx + 1;
    });
  }, [setCurrentAttachmentIdx, attachments.length]);

  const viewPrev = useCallback(() => {
    if (videoEl.current) videoEl.current.pause();
    setCurrentAttachmentIdx((prevIdx) => {
      return prevIdx === 0 ? attachments.length - 1 : prevIdx - 1;
    });
  }, [setCurrentAttachmentIdx, attachments.length]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.which === 37) viewPrev();
      if (e.which === 39) viewNext();
    },
    [viewPrev, viewNext]
  );

  const handleAfterOpen = () => {
    if (videoEl.current && attachments.length === 1) {
      videoEl.current.play();
    }
    document.addEventListener('keydown', handleKeyDown);
  };

  const handleRequestClose = () => {
    if (videoEl.current) videoEl.current.pause();
    document.removeEventListener('keydown', handleKeyDown);
    onRequestClose();
  };

  // Remove the handleKeyDown event listener on unmount
  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Get the width and height of the modal
  const { isMobile } = useIsMobile();
  const aspectRatio = (attachment && attachment.aspectRatio) || 1;
  const { width: windowWidth, height: windowHeight } =
    useDebouncedWindowDimensions();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const gutter = isMobile ? 12 : 192;
      const maxWidth = windowWidth - gutter;
      const maxHeight = windowHeight - gutter;
      let height = 0;
      let width = 0;
      if (aspectRatio > 1) {
        height = maxHeight;
        width = height / (1 * aspectRatio);
      } else {
        width = maxWidth;
        height = Math.min(width * aspectRatio, maxHeight);
        if (height === maxHeight) width = height / (1 * aspectRatio);
      }
      return { width, height };
    };

    setDimensions(calculateDimensions());
  }, [aspectRatio, windowWidth, windowHeight, isMobile]);

  return (
    <StyledModal
      onAfterOpen={handleAfterOpen}
      appElement={document.body}
      isOpen={isOpen}
      contentLabel={t('Lightbox__ContentLabel')}
      onRequestClose={handleRequestClose}
      closeTimeoutMS={0}
    >
      <CloseButton onClick={handleRequestClose}>
        <IconClose />
      </CloseButton>

      <Wrapper hasMetaData={!!attachment.request}>
        {attachments.length > 1 && (
          <>
            <LightboxArrow next onClick={viewNext} />
            <LightboxArrow prev onClick={viewPrev} />
          </>
        )}
        {isOpen && (
          <BlurUpWrapper
            style={{
              width: `${dimensions.width}px`,
              height: `${dimensions.height}px`,
            }}
          >
            {attachment.type === 'Photo' ? (
              <>
                <BlurUp
                  key={attachment.id}
                  srcSet={attachment.mediaUrl}
                  preview={attachment.thumbnail}
                  preventLoading={!isOpen}
                  contain
                />

                <Faces attachment={attachment} />
              </>
            ) : (
              <video
                key={attachment.id}
                ref={videoEl}
                muted
                playsInline
                controls
                poster={attachment.thumbnail}
              >
                <source
                  src={attachment.mediaUrl}
                  type={attachment.mediaContentType.replace('quicktime', 'mp4')}
                />
              </video>
            )}
          </BlurUpWrapper>
        )}

        <LightboxMetadata attachment={attachment} />
      </Wrapper>
    </StyledModal>
  );
}
