#import '../fragments/AttachmentFields.graphql'
#import '../fragments/DraftFields.graphql'
mutation AddAttachment($params: JSON!, $socketId: ID!, $mmsMessage: Boolean) {
  addAttachment(params: $params, socketId: $socketId, mmsMessage: $mmsMessage) {
    attachment {
      ...AttachmentFields
      suggestions {
        id
        platforms {
          id
        }
        drafts {
          ...DraftFields
        }
      }
    }
    errors
  }
}
