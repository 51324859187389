import React from 'react';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';
import InfoCard from '../InfoCard';
import { AddMediaAppearance, MediaAppearance } from './components';
import { RainbowGroup } from 'components/RainbowGroup';

interface Props {
  attachment: Attachment;
}

export default function MediaAppearances({ attachment }: Props) {
  const { t } = useIntl();
  let titleSuffix = 'Video';
  if (attachment.mediaContentType.match(/gif/i)) {
    titleSuffix = 'GIF';
  } else if (attachment.type === 'Photo') {
    titleSuffix = 'Photo';
  }
  return (
    <InfoCard
      title={t(`MediaAppearances__Title--${titleSuffix}`)}
      totalItemsPillAmount={attachment.mediaAppearances.length}
    >
      <RainbowGroup>
        {attachment.mediaAppearances.map((mediaAppearance) => (
          <MediaAppearance
            key={mediaAppearance.id}
            mediaAppearance={mediaAppearance}
          />
        ))}
      </RainbowGroup>
      {!attachment.mediaAppearances.length && (
        <p>{t('MediaAppearances__Empty')}</p>
      )}

      {attachment.viewerCanEdit && (
        <AddMediaAppearance attachment={attachment} />
      )}
    </InfoCard>
  );
}
