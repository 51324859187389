#import '../fragments/DraftFields.graphql'

mutation RemoveDraft($suggestionIds: [ID!]!, $platform: String!) {
  removeDraft(suggestionIds: $suggestionIds, platform: $platform) {
    suggestions {
      id
      updatedAt
      platforms {
        id
      }
      drafts {
        ...DraftFields
      }
    }
  }
}
