#import '../fragments/SuggestionFields.graphql'
#import '../fragments/SuggestionIntendedRecipients.graphql'

mutation AddTagsAutomatically($ids: [ID!]!) {
  addTagsAutomatically(ids: $ids) {
    errors
    suggestions {
      ...SuggestionFields
      ...SuggestionIntendedRecipients
    }
  }
}
