import { useState } from 'react';
import Popover from './Popover';
import { Slot } from '@radix-ui/react-slot';
import Button from './Button';

type Props = Omit<React.ComponentPropsWithoutRef<typeof Popover>, 'content'> & {
  children: React.ReactNode;
  content?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  skip?: boolean;
};

export default function ConfirmPopover(props: Props) {
  const {
    children,
    content,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    skip,
    ...popoverProps
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  if (skip) return <Slot onClick={onConfirm}>{children}</Slot>;
  return (
    <Popover
      content={
        <>
          <div className="mb-2 font-semibold text-dark">
            {content ? content : 'Are you sure?'}
          </div>
          <div className="flex justify-end gap-2">
            <Button
              inlineBlock
              cancel
              onClick={() => {
                onCancel?.();
                setIsOpen(false);
              }}
            >
              {cancelText ? cancelText : 'Cancel'}
            </Button>

            <Button
              inlineBlock
              filledBg
              onClick={() => {
                setIsOpen(false);
                onConfirm?.();
              }}
              type="button"
            >
              {confirmText ? confirmText : 'Continue'}
            </Button>
          </div>
        </>
      }
      isOpen={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      align="start"
      {...popoverProps}
    >
      <Slot onClick={() => setIsOpen(true)}>{children}</Slot>
    </Popover>
  );
}
