import ADD_SUGGESTION_ALBUM from 'graphql/mutations/addSuggestionAlbum.graphql';
import apolloClient from '../apolloClient';

type IDs = number | string | (number | string)[];

const addSuggestionAlbum = async (suggestionIds: IDs, albumIds: IDs) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: ADD_SUGGESTION_ALBUM,
    variables: { suggestionIds, albumIds },
    refetchQueries: ['SuggestionAlbums'],
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default addSuggestionAlbum;
