import React, { useState } from 'react';
import { AttachmentModal } from 'components';
import Attachment from 'types/Attachment';
import useKey from '@rooks/use-key';
import THUMBNAIL_ATTACHMENT_QUERY from './thumbnailAttachment.graphql';
import { useQuery } from 'react-apollo';

interface Props {
  attachments: Attachment[];
  onRequestClose: () => void;
}

interface QueryData {
  attachment: Attachment;
}

const ThumbnailAttachmentModalContainer = ({
  onRequestClose,
  attachments,
}: Props) => {
  const [activeAttachmentIndex, setActiveAttachmentIndex] = useState(0);
  const id = attachments[activeAttachmentIndex].id;

  const { data, error } = useQuery<QueryData>(THUMBNAIL_ATTACHMENT_QUERY, {
    variables: { id },
  });

  useKey(['ArrowRight'], () => {
    if (attachments[activeAttachmentIndex + 1]) {
      setActiveAttachmentIndex(activeAttachmentIndex + 1);
    } else {
      setActiveAttachmentIndex(0);
    }
  });

  useKey(['ArrowLeft'], () => {
    if (activeAttachmentIndex === 0) {
      setActiveAttachmentIndex(attachments.length - 1);
    } else {
      setActiveAttachmentIndex(activeAttachmentIndex - 1);
    }
  });

  useKey(['Escape'], onRequestClose);

  return (
    <>
      <AttachmentModal
        key={id}
        attachment={data && data.attachment}
        error={error}
        onRequestClose={onRequestClose}
      />
    </>
  );
};

export default ThumbnailAttachmentModalContainer;
