#import '../fragments/AttachmentFields.graphql'
#import '../fragments/FaceFields.graphql'

mutation ScanAttachmentForFaces($id: ID!) {
  scanAttachmentForFaces(id: $id) {
    attachment {
      ...AttachmentFields
      scannedForFaces
      faces: highQualityFaces {
        ...FaceFields
      }
    }
    errors
  }
}
