#import '../fragments/SuggestionFields.graphql'

mutation UpdatePlatformBag(
  $suggestionIds: [ID!]!
  $platform: String!
  $countries: [JSON!]
  $locales: [JSON!]
  $gender: String
  $minAge: Int
  $maxAge: Int
) {
  updatePlatformBag(
    suggestionIds: $suggestionIds
    platform: $platform
    countries: $countries
    locales: $locales
    gender: $gender
    minAge: $minAge
    maxAge: $maxAge
  ) {
    suggestions {
      ...SuggestionFields
    }
    errors
  }
}
