#import '../fragments/TwilioMessageFields.graphql'

query TwilioMessages($chatId: ID!, $pageSize: Int!, $cursor: String) {
  twilioMessages(chatId: $chatId, first: $pageSize, after: $cursor)
    @connection(key: "twilioMessages", filter: ["chatId"]) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...TwilioMessageFields
      }
    }
  }
}
