import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { FormattedMessage } from 'react-intl';
import { grid, colors } from 'styles/theme';
import Attachment from 'types/Attachment';
import { IconDownload } from 'icons';
import mutation from 'graphql/mutations/downloadMedia.graphql';

interface Props {
  attachment: Attachment;
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: opacity 0.2s;
  z-index: 3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.white};
  padding: ${grid(1.5)} ${grid(3)};
  width: 100%;
  min-height: ${grid(7)};
  font-size: 14px;

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .name {
    margin-right: ${grid(1)};
    color: ${colors.darkText};
  }

  .date {
    color: ${colors.lightText};
  }

  .download {
    position: relative;
    top: 2px;
    margin-left: auto;
    cursor: pointer;
    color: ${colors.black};

    svg {
      width: ${grid(2.5)};
      height: ${grid(2.5)};
    }
  }

  .notes {
    text-align: left;
    line-height: ${grid(2)};
    font-size: 12px;
  }
`;

interface MutationData {
  downloadMedia: { errors?: boolean; url?: string };
}

export default function LightboxMetadata({ attachment }: Props) {
  if (!attachment.request) return null;

  return (
    <Wrapper>
      <div className="row">
        <span className="name">{attachment.request.publisher.name}</span>

        <div className="date">
          <FormattedMessage
            id="Lightbox__CreatedDate"
            values={{ createdAt: attachment.createdAt }}
          />
        </div>

        <Mutation<MutationData>
          mutation={mutation}
          variables={{
            socketId: 'abc',
            suggestionIds: null,
            attachmentIds: attachment.id,
          }}
          onCompleted={(data) => {
            const url = data.downloadMedia.url;
            if (url) window.location.href = url;
          }}
        >
          {(downloadMedia) => (
            <div className="download" onClick={() => downloadMedia()}>
              <IconDownload />
            </div>
          )}
        </Mutation>
      </div>

      {attachment.notes && <div className="notes">{attachment.notes}</div>}
    </Wrapper>
  );
}
