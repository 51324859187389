import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { createElement } from 'react';
import { Avatar } from 'components';

interface Props {
  color?:
    | 'error'
    | 'success'
    | 'successDark'
    | 'warning'
    | 'info'
    | 'light'
    | 'white'
    | 'blue'
    | 'darkInfo'
    | 'athlete'
    | 'athleteDark'
    | 'socialiePink';
  size?: 'lg' | 'md' | 'sm';
  image?: string;
  imageFallback?: string;
  children: React.ReactNode;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

const PillBadge = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    color = 'info',
    size = 'md',
    image,
    imageFallback,
    icon,
    className,
    children,
  } = props;

  const textColor = clsx({
    'text-error': color === 'error',
    'text-warning': color === 'warning',
    'text-teal': color === 'success',
    'text-grey4': color === 'info',
    'text-dark': color === 'light' || color === 'white',
    'text-white':
      color === 'darkInfo' ||
      color === 'athleteDark' ||
      color === 'athlete' ||
      color === 'successDark' ||
      color === 'socialiePink',
    'text-blue': color === 'blue',
  });

  return (
    <div
      ref={ref}
      className={clsx(
        `flex max-w-full items-center justify-center overflow-hidden rounded-full border border-solid px-1`,
        {
          // Background Color
          'bg-errorBg': color === 'error',
          'bg-warningBg': color === 'warning',
          'bg-tealBg': color === 'success',
          'bg-teal': color === 'successDark',
          'bg-grey7': color === 'info',
          'bg-grey2': color === 'darkInfo',
          'bg-athleteDarkInactive': color === 'athleteDark',
          'bg-blueBg': color === 'blue',
          'bg-purple': color === 'athlete',
          'bg-white': color === 'white',
          'bg-socialiePink': color === 'socialiePink',

          // Size
          'h-4': size === 'lg',
          'h-3': size === 'md',
          'h-2.5': size === 'sm',

          // Padding
          'pl-0.25': !!image || !!imageFallback,

          // Border
          'border-transparent': color !== 'light',
          'border-grey7': color === 'light',
        },
        className
      )}
    >
      {image && (
        <img
          src={image}
          className={clsx('mr-0.5 rounded-full object-cover', {
            'h-[28px] w-[28px]': size === 'lg',
            'h-[20px] w-[20px]': size === 'md',
            'h-[16px] w-[16px]': size === 'sm',
          })}
          alt=""
        />
      )}

      {!image && imageFallback && (
        <div
          className={clsx('mr-0.5 rounded-full object-cover', {
            'h-[28px] w-[28px]': size === 'lg',
            'h-[20px] w-[20px]': size === 'md',
            'h-[16px] w-[16px]': size === 'sm',
          })}
        >
          <Avatar src={image} name={imageFallback} theme={{ noBorder: true }} />
        </div>
      )}

      {icon &&
        createElement(icon, {
          className: clsx(
            'mr-0.5 flex-shrink-0',
            textColor,
            size === 'sm' ? 'w-1.75 h-1.75' : 'w-2 h-2'
          ),
        })}

      <span
        className={clsx(
          'flex h-full items-center overflow-hidden font-bold',
          textColor,
          {
            'text-14': size === 'lg' || size === 'md',
            'text-12': size === 'sm',
          }
        )}
      >
        <span className="bump-up-1 truncate">{children}</span>
      </span>
    </div>
  );
});

PillBadge.displayName = 'PillBadge';

export default PillBadge;
