#import '../fragments/AttachmentFields.graphql'
#import '../fragments/SuggestionFields.graphql'
#import '../fragments/SuggestionIntendedRecipients.graphql'

mutation VideoAttachmentProcessed($id: ID!) {
  videoAttachmentProcessed(id: $id) {
    attachment {
      ...AttachmentFields
      suggestions {
        ...SuggestionFields
        ...SuggestionIntendedRecipients
      }
    }
    errors
  }
}
