import React from 'react';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import { Query } from '@apollo/react-components';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import Suggestion from 'types/Suggestion';
import Tag from 'types/Tag';
import SelectOption from 'types/SelectOption';
import { Form } from 'components';
import TAGS from 'graphql/queries/tags.graphql';
import WithIntl from 'components/WithIntl';
import { IconTag } from 'icons';
import addTagsToSuggestions from 'graphql/operations/addTagsToSuggestions';
import removeTagsFromSuggestions from 'graphql/operations/removeTagsFromSuggestions';
import RelayConnection from 'types/RelayConnection';
import { mapNodes } from 'helpers';

interface OwnProps {
  suggestions: Suggestion[];
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

interface State {
  value: SelectOption[];
}

interface QueryData {
  tags: RelayConnection<Tag>;
}

const tagsToOptions = (tags: Tag[]) =>
  tags.map((t) => ({ value: t.id, label: t.name }));

const mapValues = (options: SelectOption[]) => options.map((o) => o.value);

class Tags extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.getInitialValue(),
    };
  }

  getInitialValue = () => {
    const { suggestions } = this.props;
    if (suggestions.length === 1) return tagsToOptions(suggestions[0].tags);
    return [];
  };

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState({ value: this.getInitialValue() });
      return;
    }

    // Fires when we create a new tag and then get it back from the server
    if (isEqual(prevIds, currentIds) && currentIds.length === 1) {
      const suggestion = this.props.suggestions[0];
      const prevSuggestion = prevProps.suggestions[0];
      const newTags = tagsToOptions(suggestion.tags);
      const oldTags = tagsToOptions(prevSuggestion.tags);
      if (!isEqual(newTags, oldTags)) {
        this.setState({ value: newTags });
      }
    }
  }

  onChange = (value: SelectOption[]) => {
    const oldValue = this.state.value;
    this.props.startSaving('tags');
    this.addTags(without(value, ...oldValue));
    this.removeTags(without(oldValue, ...value));
    this.setState({ value });
  };

  // Use the add/removeTagsFromSuggestions mutations because they can handle
  // receiving existing tagIds as well as handling the NEW__ tags created by
  // react-select
  addTags = async (tags: SelectOption[]) => {
    if (!tags.length) return;
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    await addTagsToSuggestions(suggestionIds, mapValues(tags));
    this.props.stopSaving('tags');
  };

  removeTags = async (tags: SelectOption[]) => {
    if (!tags.length) return;
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    await removeTagsFromSuggestions(suggestionIds, mapValues(tags));
    this.props.stopSaving('tags');
  };

  render() {
    const { suggestions } = this.props;
    const { value } = this.state;

    return (
      <WithIntl>
        {(_, t) => (
          <Query<QueryData> query={TAGS}>
            {({ data, loading, error }) => {
              const tags = data?.tags ? mapNodes(data.tags.edges) : [];

              const options = tags.map((t: Tag) => ({
                value: t.id,
                label: t.name,
              }));

              const loadedPlaceholder =
                suggestions.length === 1
                  ? t('SuggestionForm__TagsPlaceholder')
                  : t('SuggestionForm__TagsMultiPlaceholder', {
                      count: suggestions.length,
                    });

              const placeholder = loading
                ? t('Global__TagsLoading')
                : loadedPlaceholder;

              return (
                <Form.Select
                  data-qa="suggestion-form-tags-select-tags"
                  isMulti
                  creatable
                  complexValues
                  options={options}
                  value={!loading && value}
                  onChange={this.onChange}
                  isClearable={false}
                  placeholder={placeholder}
                  icon={<IconTag />}
                />
              );
            }}
          </Query>
        )}
      </WithIntl>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(Tags);
