#import '../fragments/FaceFields.graphql'

mutation AddMediaAppearances(
  $attachmentId: ID!
  $publisherIds: [ID!]!
  $faceId: ID
) {
  addMediaAppearances(
    attachmentId: $attachmentId
    publisherIds: $publisherIds
    faceId: $faceId
  ) {
    errors
    attachment {
      id
      mediaAppearances {
        id
        publisherNameFound
        publisherFaceFound
        sentimentTag
        face {
          id
        }
        publisher {
          id
          name
          image
        }
      }
      faces: highQualityFaces {
        ...FaceFields
      }
    }
  }
}
