#import '../fragments/RecipientContactMethodFields.graphql'
#import '../fragments/RecipientPublisherTagFields.graphql'
#import '../fragments/RecipientPublisherFields.graphql'
#import '../fragments/RecipientPublisherUserFields.graphql'

query RecipientsSearch(
  $query: String
  $includePublisherTags: Boolean
  $excludePublisherIds: [ID!]
) {
  recipientSearch(
    query: $query
    includePublisherTags: $includePublisherTags
    excludePublisherIds: $excludePublisherIds
  ) {
    recipients {
      id
      combinedId
      contactMethod {
        ...RecipientContactMethodFields
      }
      publisherTag {
        ...RecipientPublisherTagFields
      }
      publisherUser {
        ...RecipientPublisherUserFields
      }
      publisher {
        ...RecipientPublisherFields
      }
    }
    totalPublishers
    totalPublisherTags
  }

  currentSuggester {
    id
    twilioNumbers {
      id
      countryCode
    }
    accountConfiguration {
      id
      allowWorldwideSms
      domesticSmsCountryCode
      countryCodeWhitelist
      contactProtocolBlacklist
      enableWhatsApp
    }
  }
}
