#import '../fragments/SuggestionFields.graphql'
#import '../fragments/RequestFields.graphql'
#import '../fragments/PostInsightFields.graphql'

mutation addIntendedRecipients(
  $suggestionId: ID!
  $intendedRecipients: [IntendedRecipientInput!]!
  $socketId: String
  $skipNotifications: Boolean
) {
  addIntendedRecipients(
    suggestionId: $suggestionId
    intendedRecipients: $intendedRecipients
    socketId: $socketId
    skipNotifications: $skipNotifications
  ) {
    suggestion {
      ...SuggestionFields
      requests {
        ...RequestFields
        posts {
          id
          status
          platform
          reach
          engagement
          postInsight {
            ...PostInsightFields
          }
        }
      }
    }
    errors
  }
}
