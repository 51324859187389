fragment AccountConfigurationFields on AccountConfiguration {
  id
  enableStories
  requireMfa
  primaryColor
  logoUrl
  logoUrl
  logoBorderColor
  topBarBorderColor
  loginButtonBgColor
  loginButtonFgColor
  backgroundColor
  backgroundOpacity
  backgroundImageMobileUrl
  backgroundImageDesktopUrl
  backgroundImageMobileOffset
  backgroundImageDesktopOffset
  logoNormal
  logoEmailWidth
  logoEmailHeight
  creditFacebook
  creditTwitter
  creditInstagram
  maxAthletes
  maxSuggesters
  maxSuggesterUploaders
  remainingAthletes
  autoAddPhotoCreditsInBuilder
}
