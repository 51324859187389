import React from 'react';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import { Query } from '@apollo/react-components';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import Suggestion from 'types/Suggestion';
import Album from 'types/Album';
import SelectOption from 'types/SelectOption';
import { Form, QTooltip } from 'components';
import ALBUMS_QUERY from 'graphql/queries/suggestionAlbums.graphql';
import WithIntl from 'components/WithIntl';
import { IconMediaLibrary } from 'icons';
import addSuggestionAlbum from 'graphql/operations/addSuggestionAlbum';
import removeSuggestionAlbums from 'graphql/operations/removeSuggestionAlbum';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  suggestions: Suggestion[];
  handleOnChange?: (e: string[]) => void;
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

interface State {
  value: SelectOption[];
}

interface QueryData {
  suggestionAlbums: Album[];
}

const albumsToOptions = (albums: Album[]) =>
  albums.map((a) => ({ value: a.id, label: a.name }));

const mapValues = (options: SelectOption[]) => options.map((o) => o.value);

class Albums extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: this.getInitialValue() };
  }

  getInitialValue = () => {
    const { suggestions } = this.props;
    if (suggestions.length === 1) return albumsToOptions(suggestions[0].albums);
    return [];
  };

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState({ value: this.getInitialValue() });
      return;
    }

    // Fires when we create a new album and then get it back from the server
    if (isEqual(prevIds, currentIds) && currentIds.length === 1) {
      const suggestion = this.props.suggestions[0];
      const prevSuggestion = prevProps.suggestions[0];
      const newAlbums = albumsToOptions(suggestion.albums);
      const oldAlbums = albumsToOptions(prevSuggestion.albums);
      if (!isEqual(newAlbums, oldAlbums)) {
        this.setState({ value: newAlbums });
      }
    }
  }

  onChange = (value: SelectOption[]) => {
    const oldValue = this.state.value;
    this.props.startSaving('albums');
    this.addAlbums(without(value, ...oldValue));
    this.removeAlbums(without(oldValue, ...value));
    this.setState({ value });
  };

  // Use the add/removeSuggestionAlbums mutations because they can handle
  // receiving existing albumIds as well as handling the NEW__ albums created by
  // react-select
  addAlbums = async (albums: SelectOption[]) => {
    if (!albums.length) return;
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    await addSuggestionAlbum(suggestionIds, mapValues(albums));
    this.props.stopSaving('albums');
  };

  removeAlbums = async (albums: SelectOption[]) => {
    if (!albums.length) return;
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    await removeSuggestionAlbums(suggestionIds, mapValues(albums));
    this.props.stopSaving('albums');
  };

  render() {
    const { suggestions } = this.props;
    const { value } = this.state;

    return (
      <WithIntl>
        {(_, t) => (
          <Query<QueryData> query={ALBUMS_QUERY}>
            {({ data, loading, error }) => {
              const options =
                data && data.suggestionAlbums
                  ? data.suggestionAlbums.map((t) => ({
                      label: t.name,
                      value: t.id,
                    }))
                  : [];

              const loadedPlaceholder =
                suggestions.length === 1
                  ? t('SuggestionForm__AlbumsPlaceholder')
                  : t('SuggestionForm__AlbumsMultiPlaceholder', {
                      count: suggestions.length,
                    });

              const placeholder = loading
                ? t('SuggestionForm__AlbumsLoading')
                : loadedPlaceholder;

              return (
                <Form.Field>
                  <Form.Label bold>
                    <FormattedMessage id="SuggestionForm__Albums" />
                    <QTooltip
                      content={<FormattedMessage id="Albums__Tooltip" />}
                    />
                  </Form.Label>

                  <Form.Select
                    data-qa="suggestion-form-albums-select-album"
                    isMulti
                    creatable
                    complexValues
                    options={options}
                    value={!loading && value}
                    onChange={this.onChange}
                    isClearable={false}
                    placeholder={placeholder}
                    icon={<IconMediaLibrary />}
                  />
                </Form.Field>
              );
            }}
          </Query>
        )}
      </WithIntl>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(Albums);
