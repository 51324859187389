query AttachmentAlbums($cursor: String, $filter: JSON, $first: Int = 40) {
  attachmentAlbums(first: $first, after: $cursor, filter: $filter) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        publishers {
          id
          name
          image
        }
        publisherTags {
          id
          name
        }
        anyPublisherCanView
        attachmentAlbumAttachments(first: 1) {
          totalCount
          edges {
            node {
              id
              attachmentAlbumId
              attachment {
                id
                title
                mediaUrl
                mediaFilename
                thumbnail(size: "medium")
                caption
                keywords
                createdAt
                createdOn
                aspectRatio
                mediaSize
                mediaAppearances {
                  id
                  publisher {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
