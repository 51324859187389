import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { Pills } from '..';
import Album from 'types/Album';
import mutation from 'graphql/mutations/removeSuggestionAlbum.graphql';

interface Props {
  suggestionId: number | string;
  album: Album;
  large?: boolean;
  small?: boolean;
}

const PillWrapper = styled.div`
  ${(props: any) => props.isRemoving && 'opacity: 0.3'};
  line-height: normal;
` as any;

interface MutationData {
  removeSuggestionAlbum: { errors?: boolean; suggestions: { id: string }[] };
}

const SuggestionAlbum = ({ album, large, small, suggestionId }: Props) => {
  const variables = { suggestionIds: suggestionId, albumIds: album.id };
  return (
    <Mutation<MutationData>
      key={album.id}
      mutation={mutation}
      variables={variables}
    >
      {(removeSuggestionAlbum, { loading }) => (
        <PillWrapper isRemoving={loading}>
          <Pills.Pill
            large={large}
            small={small}
            onRemove={removeSuggestionAlbum}
          >
            {album.name}
          </Pills.Pill>
        </PillWrapper>
      )}
    </Mutation>
  );
};

export default SuggestionAlbum;
