import React, { useState } from 'react';
import { useIntl } from 'hooks';
import { Form, Button } from 'components';
import VALIDATE_PASSWORD from 'graphql/mutations/validatePassword.graphql';
import { useMutation } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';
import { IconLoader } from 'icons';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import User from 'types/User';

interface VerifyPasswordProps {
  user: User | SemiLoggedInUser;
  onSuccess: () => void;
  onRetrievePassword?: (value: string) => void;
}

export default function VerifyPassword({
  user,
  onSuccess,
  onRetrievePassword,
}: VerifyPasswordProps) {
  const { t } = useIntl();
  const { email } = user;
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState('');

  const [validatePassword, { loading }] = useMutation(VALIDATE_PASSWORD, {
    variables: {
      password,
    },
    onCompleted: (data) => {
      if (data.validatePassword.valid) {
        onRetrievePassword?.(password);
        onSuccess();
      } else {
        setValidationError(t('VerifyPassword__InvalidPassword'));
      }
    },
    onError: () => {
      flashMessage('Global__UnexpectedError', { style: 'error' });
    },
  });

  return (
    <>
      <div className="text-left">
        <div className="mb-2">
          <p className="text-dark">
            {t('Security__ManageMfa--VerifyWithPassword-Label', {
              email,
            })}
          </p>
        </div>
        <div className="mb-2.5 w-30">
          <Form.TextInput
            value={password}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                validatePassword();
              }
            }}
            name="password"
            placeholder={t(
              'Security__ManageMfa--VerifyWithPassword-Placeholder'
            )}
            errors={validationError}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            autoFocus
          />
        </div>
        <div className="flex items-center justify-end">
          <Button
            filledBg
            inlineBlock
            onClick={() => validatePassword()}
            disabled={loading || !password.length}
          >
            <span className="flex items-center">
              {loading && <IconLoader className="mr-1 h-2 w-2" />}
              {t('Global__Next')}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
}
