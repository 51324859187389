import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Loader } from '..';

interface Props {
  hasNextPage?: boolean;
  loadNextPage: () => void;
  children: React.ReactNode;
  loading?: boolean;
  useParent?: boolean;
  className?: string;
  isReverse?: boolean;
  threshold?: number;
  initialLoad?: boolean;
}

export default function InfiniteScrollList({
  children,
  hasNextPage,
  loadNextPage,
  loading,
  useParent,
  className,
  isReverse,
  initialLoad,
  threshold = 500,
}: Props) {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: !!loading,
    hasNextPage: !!hasNextPage,
    onLoadMore: loadNextPage,
    rootMargin: isReverse
      ? `${threshold}px 0px 0px 0px`
      : `0px 0px ${threshold}px 0px`,
  });

  return (
    <div className={className} ref={useParent ? rootRef : undefined}>
      {hasNextPage && isReverse && (
        <div ref={sentryRef} className="m-auto">
          <Loader />
        </div>
      )}
      {children}
      {hasNextPage && !isReverse && (
        <div ref={sentryRef} className="m-auto">
          <Loader />
        </div>
      )}
    </div>
  );
}
