import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import MediaAppearanceType from 'types/MediaAppearance';
import { flashMessage, routes, routeFor } from 'helpers';
import { Avatar } from 'components';
import {
  IconFace,
  IconMetadata,
  IconFaceFrownSolid,
  IconFaceSmileSolid,
  IconFaceNeutralSolid,
  IconTrash,
} from 'icons';
import { Tooltip } from 'components';
import { useIntl } from 'hooks';
import { FaceFields } from 'graphql/fragments/FaceFields.graphql';

interface Props {
  mediaAppearance: MediaAppearanceType;
}

const REMOVE_MEDIA_APPEARANCE = gql`
  ${FaceFields}
  mutation RemoveMediaAppearance($id: ID!) {
    removeMediaAppearance(id: $id) {
      errors
      attachment {
        id
        faces: highQualityFaces {
          ...FaceFields
        }
        mediaAppearances {
          id
          publisherNameFound
          publisherFaceFound
          sentimentTag
          publisher {
            id
            name
            image
          }
        }
      }
    }
  }
`;

export default function MediaAppearance({ mediaAppearance }: Props) {
  const { t } = useIntl();
  const { id, publisher } = mediaAppearance;

  const [removeMediaAppearance, { loading: isRemoving }] = useMutation<any>(
    REMOVE_MEDIA_APPEARANCE,
    {
      variables: { id },
      onCompleted: (data) => {
        if (!data.removeMediaAppearance.errors) return;
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
      onError: () => {
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
    }
  );

  if (isRemoving) return null;

  return (
    <div className="mb-1 flex items-center last:mb-1.5">
      <div className="mr-1 h-3 w-3 flex-shrink-0">
        <Avatar
          theme={{ rainbow: true }}
          src={publisher.image}
          name={publisher.name}
        />
      </div>
      <div className="mr-1 flex-1 truncate">
        <Link
          to={routeFor(routes.publishers.show, publisher.id)}
          className="bump-up-1 text-dark hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {publisher.name}
        </Link>
      </div>

      {mediaAppearance.publisherFaceFound && (
        <Icon
          tooltip={t('MediaAppearance__FacialRecognitionTooltip')}
          icon={IconFace}
        />
      )}
      {mediaAppearance.publisherNameFound && (
        <Icon
          tooltip={t('MediaAppearance__MetadataTooltip')}
          icon={IconMetadata}
        />
      )}
      {mediaAppearance.sentimentTag === 'positive' && (
        <Icon
          tooltip={t('MediaAppearance__PositiveTooltip', {
            name: publisher.name,
          })}
          color="text-green"
          icon={IconFaceSmileSolid}
        />
      )}
      {mediaAppearance.sentimentTag === 'negative' && (
        <Icon
          tooltip={t('MediaAppearance__NegativeTooltip', {
            name: publisher.name,
          })}
          color="text-yellow"
          icon={IconFaceFrownSolid}
        />
      )}
      {mediaAppearance.sentimentTag === 'neutral' && (
        <Icon
          tooltip={t('MediaAppearance__NeutralTooltip', {
            name: publisher.name,
          })}
          icon={IconFaceNeutralSolid}
        />
      )}

      <div className="border-l-default ml-1 flex items-center pl-0.5">
        <button
          type="button"
          onClick={() => removeMediaAppearance()}
          className="flex h-3 w-3 items-center justify-center text-bodyText opacity-50 hover:opacity-100"
          aria-label={t('Global__Delete')}
        >
          <IconTrash className="h-2 w-2" />
        </button>
      </div>
    </div>
  );
}

interface IconProps {
  tooltip: string;
  icon: React.FC<any>;
  color?: string;
}

function Icon({ tooltip, icon, color }: IconProps) {
  return (
    <Tooltip content={tooltip} theme="socialieDark">
      {React.createElement(icon, {
        className: `inline-block w-2 h-2 ml-0.5 bump-down-0.5 ${
          color ? color : 'text-light'
        }`,
      })}
    </Tooltip>
  );
}
