mutation RemoveTagsFromSuggestions($suggestionIds: [ID!]!, $tagIds: [ID!]!) {
  removeTagsFromSuggestions(tagIds: $tagIds, suggestionIds: $suggestionIds) {
    suggestions {
      id
      updatedAt
      tags {
        id
        name
      }
    }
  }
}
