#import './AttachmentFields.graphql'
#import './DraftFields.graphql'
#import './SuggesterFields.graphql'
#import './RequestFields.graphql'

fragment SuggestionFields on Suggestion {
  id
  actionableType
  createdAt
  updatedAt
  lastEditedAt
  postAt
  sentAt
  expiresAt
  status
  source
  subject
  mediaType
  deleted
  videoLength
  orientation
  instructions
  talkingPoints
  examplePostUrl
  reach
  engagement
  exampleContentUrl
  exampleContent
  exampleContentContentType
  exampleContentTitle
  exampleContentDescription
  viewerCanRemove
  sender {
    id
    firstName
    lastName
    email
  }
  suggester {
    ...SuggesterFields
    viewerRelationship
  }
  campaign {
    id
    name
  }
  tags {
    id
    name
  }
  albums {
    id
    name
  }
  attachments {
    ...AttachmentFields
  }
  platformBags {
    id
    platform
    videoCaptions {
      id
      captionFilename
      captionUrl
    }
    targets {
      id
      targetType
      value
      description
    }
  }
  platforms {
    id
  }
  drafts {
    ...DraftFields
  }
  requests {
    ...RequestFields
  }
}
