#import '../fragments/AttachmentFields.graphql'
#import '../fragments/AttachmentDetailFields.graphql'
#import '../fragments/AttachmentSuggestionFields.graphql'

query Attachment($id: ID!, $filter: JSON) {
  attachment(id: $id) {
    ...AttachmentFields
    ...AttachmentDetailFields
    suggestions {
      ...AttachmentSuggestionFields
    }
  }

  nextAttachment: attachmentSibling(
    id: $id
    filter: $filter
    direction: "next"
  ) {
    id
  }

  prevAttachment: attachmentSibling(
    id: $id
    filter: $filter
    direction: "previous"
  ) {
    id
  }
}
