import * as io from 'socket.io-client';
import MockedIo from 'socket.io-mock';
import store from 'redux/store';
import { socketConnected, socketDisconnected } from 'redux/ducks/socket';
import { chatEvent, fileProcessingEvent, csvFileEvent } from './handlers';
import generateRandomValue from 'helpers/generateRandomValue';

const SOCKET_SERVER_URL = (import.meta.env.REACT_APP_SOCKET_SERVER_URL ||
  import.meta.env.REACT_APP_API_SERVER_URL) as string;

const socket =
  process.env.NODE_ENV === 'test'
    ? new MockedIo()
    : io.connect(SOCKET_SERVER_URL);

// Core events
socket.on('connect', async () => {
  const jwt =
    localStorage.getItem('satellizer_token') || window.Socialie.tempUserToken;
  const socketId = await generateRandomValue();
  socket.emit('authenticate', { jwt, uuid: socketId });
  store.dispatch(socketConnected(socketId));
});

socket.on('disconnect', () => store.dispatch(socketDisconnected()));

// Other events
socket.on('csvFileEvent', csvFileEvent);
socket.on('fileProcessingEvent', fileProcessingEvent);
socket.on('chatEvent', chatEvent);

export default socket as ReturnType<typeof io.connect>;
