import React, { useState } from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/react-components';
import sortBy from 'lodash/sortBy';
import { FormattedMessage } from 'react-intl';
import { grid, colors } from 'styles/theme';
import {
  Button,
  Loader,
  ErrorDebug,
  InfiniteScrollList,
  Switch,
} from 'components';
import Chat from 'types/Chat';
import RelayConnection from 'types/RelayConnection';
import PageInfo from 'types/PageInfo';
import { IconPlus } from 'icons';
import { updateQuery, mapNodes } from 'helpers';
import { ChatPreview } from './components';
import ChatPanelContext from '../../ChatPanelContext';
import CHATS_QUERY from 'graphql/queries/chats.graphql';
import { useIntl } from 'hooks';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
`;

const Top = styled.div`
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)};
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChatList = styled.div`
  flex: 1;
  overflow: auto;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

interface QueryData {
  chats: RelayConnection<Chat>;
}

export default function Home() {
  const [isUnreadOnly, setIsUnreadOnly] = useState(false);
  const { t } = useIntl();

  return (
    <Query<QueryData>
      query={CHATS_QUERY}
      fetchPolicy="network-only"
      variables={{ unreadOnly: isUnreadOnly }}
    >
      {({ data, loading, error, fetchMore }) => {
        let pageInfo: PageInfo;
        let loadNextPage: () => void;

        if (data && data.chats) {
          ({ pageInfo } = data && data.chats);

          loadNextPage = () =>
            fetchMore({
              variables: {
                unreadOnly: isUnreadOnly,
                cursor: pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                updateQuery('chats', prev, fetchMoreResult),
            });
        }

        const chats = (data && data.chats && mapNodes(data.chats.edges)) || [];

        return (
          <Wrapper>
            <Top>
              <ActionBar>
                <span className="flex items-center space-x-0.5">
                  <Switch
                    isOn={isUnreadOnly}
                    onToggle={() => setIsUnreadOnly((val) => !val)}
                  />{' '}
                  <label className="text-12">
                    {t('ChatPanel__UnreadOnly')}
                  </label>
                </span>

                <ChatPanelContext.Consumer>
                  {({ gotoScreen }) => (
                    <Button
                      filledBg
                      inlineBlock
                      onClick={() => gotoScreen('chat', 'new')}
                    >
                      <IconPlus />
                      <FormattedMessage id="ChatPanel__NewMessage" />
                    </Button>
                  )}
                </ChatPanelContext.Consumer>
              </ActionBar>
            </Top>

            <ChatList>
              {error && <ErrorDebug error={error} />}
              {data && data.chats && (
                <InfiniteScrollList
                  hasNextPage={data && data.chats.pageInfo.hasNextPage}
                  loadNextPage={loadNextPage!}
                  loading={loading}
                  useParent
                >
                  {sortBy(chats, (c) => c.latestMessage.createdAt * -1).map(
                    (chat) => (
                      <ChatPreview key={chat.id} chat={chat} />
                    )
                  )}
                </InfiniteScrollList>
              )}
              {loading && <Loader delay={0} />}
            </ChatList>
          </Wrapper>
        );
      }}
    </Query>
  );
}
