#import './RecipientPublisherUserFields.graphql'

fragment RecipientPublisherTagFields on PublisherTag {
  id
  name
  publishers {
    id
    name
    identities {
      id
      twitterUploadLong
    }
    contactMethods {
      id
      protocol
      value
      wantsNotifications
    }
    suggester {
      id
      accountConfiguration {
        id
        logoUrl
      }
    }
    publisherUsers {
      ...RecipientPublisherUserFields
    }
  }
}
