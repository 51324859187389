#import '../../../graphql/fragments/AttachmentFields.graphql'
#import '../../../graphql/fragments/AttachmentDetailFields.graphql'

query ThumbnailAttachment($id: ID!) {
  attachment(id: $id) {
    ...AttachmentFields
    ...AttachmentDetailFields
    contentRequestSubmission {
      id
      comments
    }
  }
}
