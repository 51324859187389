import SET_SUGGESTION_CAMPAIGN from 'graphql/mutations/setSuggestionCampaign.graphql';
import apolloClient from '../apolloClient';

type IDs = number | string | (number | string)[];
type ID = number | string;

const setSuggestionCampaign = async (suggestionIds: IDs, campaignId?: ID) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: SET_SUGGESTION_CAMPAIGN,
    variables: { suggestionIds, campaignId },
    refetchQueries: ['Campaigns'],
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default setSuggestionCampaign;
