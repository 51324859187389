import { useState } from 'react';
import { IconCaretLeft, IconCaretRight } from 'icons';
import { AsyncPublisherSelect, Avatar, Button, Modal } from 'components';
import { useIntl } from 'hooks';
import { ScrollingFileList } from './MediaUpload';
import { RainbowGroup } from 'components/RainbowGroup';
import Publisher from 'types/Publisher';
import ConfirmPopover from 'components/ConfirmPopover';
import uniqBy from 'lodash/uniqBy';
import useKey from '@rooks/use-key';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  files: Record<string, File>;
  taggings: Record<string, Array<Pick<Publisher, 'id' | 'name' | 'image'>>>;
  onUpdate: (
    key: string,
    publishers: Array<Pick<Publisher, 'id' | 'name' | 'image'>>
  ) => void;
  onDone: () => void;
}

export default function MediaUploadTagging(props: Props) {
  const { files, taggings, onUpdate, onDone } = props;
  const { t } = useIntl();
  const [currentIndex, setCurrentIndex] = useState(0);
  const keys = Object.keys(files);
  const currentKey = keys[currentIndex];
  const currentFile = files[currentKey];
  const currentPreviewUrl = URL.createObjectURL(currentFile);
  const previewClassName = 'block w-full h-60 object-contain';
  const currentPublisherList = taggings[currentKey] || [];

  // Get a list of publishers that were tagged in other files but not this one
  const allPublishers = uniqBy(Object.values(taggings).flat(), 'id').filter(
    (p) => !currentPublisherList.find((cp) => cp.id === p.id)
  );

  useKey(['ArrowRight'], () => {
    setCurrentIndex((idx) => Math.min(keys.length - 1, idx + 1));
  });

  useKey(['ArrowLeft'], () => {
    setCurrentIndex((idx) => Math.max(0, idx - 1));
  });

  return (
    <>
      <div className="mb-5">
        <div className="-m-3 bg-greyBg p-3 pb-1">
          {currentFile.type?.match('video') ? (
            <video
              key={currentPreviewUrl}
              controls
              playsInline
              muted
              autoPlay
              className={`${previewClassName} bg-black`}
              onLoadedMetadata={(e) => {
                // show a "poster" on iOS devices
                const video = e.currentTarget;
                video.pause();
                video.currentTime = 0.1;
              }}
            >
              <source
                src={currentPreviewUrl}
                type={currentFile.type.replace('quicktime', 'mp4')}
              />
            </video>
          ) : (
            <img
              src={currentPreviewUrl}
              alt={currentFile.name}
              className={previewClassName}
            />
          )}

          <div className="mt-1 flex items-center justify-center gap-2 text-dark">
            <button
              onClick={() => setCurrentIndex((idx) => Math.max(0, idx - 1))}
              className="h-3 w-3 p-0.25"
            >
              <IconCaretLeft className="h-full w-full" />
            </button>

            <span className="bump-up-1">
              {currentIndex + 1}/{keys.length}
            </span>

            <button
              onClick={() =>
                setCurrentIndex((idx) => Math.min(keys.length - 1, idx + 1))
              }
              className="h-3 w-3 p-0.25"
            >
              <IconCaretRight className="h-full w-full" />
            </button>
          </div>
        </div>
      </div>

      <AsyncPublisherSelect
        value={[]}
        initialOptions={allPublishers.map((p) => ({
          value: p.id,
          label: p.name ?? '',
          image: p.image,
        }))}
        onChange={(val) => {
          if (!val?.value) return;

          onUpdate(
            currentKey,
            uniqBy(
              [
                { id: val.value, name: val.label, image: val.image },
                ...currentPublisherList,
              ],
              'id'
            )
          );
        }}
        complexValues
      />

      <PublisherList
        key={currentKey}
        publishers={currentPublisherList}
        onRemove={(id) =>
          onUpdate(
            currentKey,
            currentPublisherList.filter((p) => p.id !== id)
          )
        }
      />

      <Modal.Actions>
        <Button filledBg inlineBlock onClick={onDone}>
          {t('MediaUpload__DoneTagging')}
        </Button>
      </Modal.Actions>
    </>
  );
}

interface PublisherListProps {
  publishers: Props['taggings'][string];
  onRemove: (id: number) => void;
}

function PublisherList(props: PublisherListProps) {
  const { publishers, onRemove } = props;
  const { t } = useIntl();

  return (
    <ScrollingFileList
      fileCount={publishers.length}
      containerClassName="max-h-22 pt-2"
    >
      <RainbowGroup>
        <AnimatePresence>
          {publishers.map((publisher) => (
            <motion.div
              key={publisher.id}
              className="mb-1 flex items-center text-left last:mb-1.5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="mr-1 h-3 w-3 flex-shrink-0">
                <Avatar
                  theme={{ rainbow: true }}
                  src={publisher.image}
                  name={publisher.name}
                />
              </div>
              <div className="mr-1 flex-1 truncate">
                <span className="bump-up-1 text-dark hover:underline">
                  {publisher.name}
                </span>
              </div>

              <ConfirmPopover
                content={t('MediaUpload__ConfirmRemovePublisher', {
                  name: publisher.name,
                })}
                onConfirm={() => onRemove(publisher.id)}
                align="end"
              >
                <Button inlineBlock mono>
                  {t('MediaUpload__RemovePublisher')}
                </Button>
              </ConfirmPopover>
            </motion.div>
          ))}
        </AnimatePresence>
      </RainbowGroup>
    </ScrollingFileList>
  );
}
