import { useMutation } from 'react-apollo';
import User from 'types/User';
import { useState } from 'react';
import VALIDATE_OTP from 'graphql/mutations/validateOneTimePassword.graphql';
import { flashMessage } from 'helpers';
import socket from 'socket';

interface MutationData {
  validateOneTimePassword: {
    valid?: boolean;
    errors?: string;
    jwt?: string;
    user?: User;
  };
}

interface Options {
  onCompleted?: () => any;
  onFailed?: () => any;
  onError?: () => any;
}

export default function useValidateOtp(opts = {} as Options) {
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);

  const [validateOtp, { loading }] = useMutation<MutationData>(VALIDATE_OTP, {
    onCompleted: (data) => {
      if (data.validateOneTimePassword.valid) {
        window.Socialie.tempUserToken = undefined;
        window.localStorage.setItem(
          'satellizer_token',
          data.validateOneTimePassword.jwt!
        );
        socket.close();
        socket.connect();
        opts.onCompleted?.();
      } else {
        setIsInvalidOtp(true);
        opts.onFailed?.();
      }
    },
    onError: () => {
      flashMessage('Global__UnexpectedError', { style: 'error' });
      opts.onError?.();
    },
  });

  const handleSubmit = (...args: Parameters<typeof validateOtp>) => {
    setIsInvalidOtp(false);
    validateOtp(...args);
  };

  return {
    validateOtp: handleSubmit,
    loading,
    isInvalidOtp,
  };
}
