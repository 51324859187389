import React from 'react';
import sum from 'lodash/sum';
import { Mutation } from '@apollo/react-components';
import { FormattedMessage } from 'react-intl';
import { ConfirmModal } from 'components';
import BatchSuggestion from 'types/BatchSuggestion';
import IndexBatchSuggestion from 'types/IndexBatchSuggestion';
import REMOVE_BATCH_SUGGESTIONS from 'graphql/mutations/removeBatchSuggestions.graphql';

interface Props {
  batchSuggestions: (BatchSuggestion | IndexBatchSuggestion)[];
  count?: number;
  small?: boolean;
  children: (onClick: () => void, loading?: boolean) => React.ReactNode;
  onCompleted?: Function;
}

interface State {
  isConfirmModalOpen: boolean;
}

interface MutationData {
  removeBatchSuggestions: {
    errors?: string;
    batchSuggestions?: { id: string; deleted: boolean }[];
  };
}

export default class RemoveBatchSuggestions extends React.Component<
  Props,
  State
> {
  state = { isConfirmModalOpen: false };

  openConfirmModal = () => this.setState({ isConfirmModalOpen: true });
  closeConfirmModal = () => this.setState({ isConfirmModalOpen: false });

  onCompleted = (data: any) => {
    if (!data.removeBatchSuggestions.errors) {
      this.setState({ isConfirmModalOpen: false }, () => {
        this.props.onCompleted && this.props.onCompleted(data);
      });
    }
  };

  render() {
    const { batchSuggestions } = this.props;
    const ids = batchSuggestions.map((b) => b.id);
    const { isConfirmModalOpen } = this.state;
    const count =
      this.props.count || this.props.count === 0
        ? this.props.count
        : sum(batchSuggestions.map((b) => b.suggestions.length));

    const heading =
      count > 1
        ? 'RemoveBatchSuggestions__ConfirmHeadingPlural'
        : 'RemoveBatchSuggestions__ConfirmHeading';

    return (
      <Mutation<MutationData>
        mutation={REMOVE_BATCH_SUGGESTIONS}
        variables={{ ids }}
        onCompleted={this.onCompleted}
        refetchQueries={['DraftsIndexQuery']}
      >
        {(removeBatchSuggestions, { loading }) => (
          <>
            {this.props.children(this.openConfirmModal)}

            <ConfirmModal
              isOpen={isConfirmModalOpen}
              onRequestClose={this.closeConfirmModal}
              onConfirm={removeBatchSuggestions}
              isLoading={loading}
              heading={heading}
            >
              <FormattedMessage
                id="RemoveBatchSuggestions__ConfirmText"
                values={{ count }}
              />
            </ConfirmModal>
          </>
        )}
      </Mutation>
    );
  }
}
