#import '../fragments/TwilioMessageFields.graphql'

mutation SendChatMessage(
  $phoneNumbers: [String!]!
  $attachmentId: ID
  $text: String!
) {
  sendChatMessage(
    phoneNumbers: $phoneNumbers
    text: $text
    attachmentId: $attachmentId
  ) {
    errors
    messages {
      ...TwilioMessageFields
    }
  }
}
