import React, { useRef, useEffect } from 'react';
import Attachment from 'types/Attachment';
import { BlurUp, Loader, Faces } from 'components';
import { useCurrentUser } from 'hooks';

interface Props {
  attachment?: Attachment;
  loading?: boolean;
}

export default function AttachmentModalThumbnail({ attachment }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentSuggester = useCurrentUser()?.currentSuggester;

  // pause the video on unmount because sometimes it mysteriously keeps playing
  useEffect(
    () => () => {
      videoRef.current && videoRef.current.pause();
    },
    []
  );

  if (!attachment) return <Loader />;

  return attachment.type === 'Photo' ? (
    <>
      <BlurUp
        contain
        srcSet={attachment.largeThumbnail || attachment.thumbnail}
        preview={attachment.blurThumbnail}
      />
      {currentSuggester?.accountConfiguration?.enableFacialRecognition && (
        <Faces attachment={attachment} />
      )}
    </>
  ) : attachment.type === 'Video' ? (
    <video muted controls autoPlay ref={videoRef}>
      <source
        src={attachment.mediaUrl}
        type={attachment.mediaContentType.replace('quicktime', 'mp4')}
      />
    </video>
  ) : null;
}
