#import '../fragments/SuggesterFields.graphql'

mutation MovePublishers($ids: [ID!]!, $destinationId: ID!) {
  movePublishers(ids: $ids, destinationId: $destinationId) {
    errors
    publishers {
      id
      suggester {
        ...SuggesterFields
      }
    }
  }
}
