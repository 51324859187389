#import "../fragments/CurrentUserFields.graphql"

mutation RemoveOauthIdentity($id: ID!) {
  removeOauthIdentity(id: $id) {
    errors
    currentUser {
      ...CurrentUserFields
    }
  }
}
