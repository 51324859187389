#import '../fragments/SuggestionFields.graphql'

mutation UpdateSuggestion($id: ID!, $updates: JSON) {
  updateSuggestion(id: $id, updates: $updates) {
    suggestion {
      ...SuggestionFields
    }
    errors
  }
}
