mutation addSuggestionAlbum($suggestionIds: [ID!]!, $albumIds: [ID!]!) {
  addSuggestionAlbum(suggestionIds: $suggestionIds, albumIds: $albumIds) {
    suggestions {
      id
      updatedAt
      albums {
        name
        id
      }
    }
    errors
  }
}
