fragment PostInsightFields on PostInsight {
  id
  updatedAt
  statsUpdateResult
  engagement
  fbComments
  fbReactions
  fbShares
  fbPostConsumptions
  fbPostEngagedUsers
  fbPostImpressions
  fbPostImpressionsUnique
  fbVideoAvgTimeWatched
  fbVideoCompleteViews
  fbVideoCompleteViewsUnique
  fbVideoViews
  fbVideoViewsUnique
  twImpressions
  twLikes
  twRetweets
  twReplies
  twVideoViews
  igEngagement
  igComments
  igLikes
  igSaved
  igShares
  igImpressions
  igVideoViews
  igPlays
}
