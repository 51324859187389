import apolloClient from '../apolloClient';
import Attachment from 'types/Attachment';
import ATTACHMENT_PROCESSED from 'graphql/mutations/videoAttachmentProcessed.graphql';

const videoAttachmentProcessed = async (attachment: Attachment) => {
  const { id } = attachment;
  const mutation = await apolloClient.mutate({
    mutation: ATTACHMENT_PROCESSED,
    variables: { id },
  });

  return mutation.data;
};

export default videoAttachmentProcessed;
