import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { Mutation } from '@apollo/react-components';
import mutation from 'graphql/mutations/logIn.graphql';
import LogIn from './LogIn';
import { grid } from 'styles/theme';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import { updateApolloCacheAfterLogin } from 'helpers';

const Wrapper = styled.div`
  ${media.tablet`
    padding-top: ${grid(10)};
  `};
`;

interface MutationData {
  logIn: {
    token?: string;
    errors: string;
    currentUser?: User;
    semiLoggedInUser?: SemiLoggedInUser;
  };
}

interface Props {
  onRetrievePassword: (p: string) => void;
}

const LogInContainer = ({ onRetrievePassword }: Props) => {
  return (
    <Wrapper>
      <Mutation<MutationData>
        mutation={mutation}
        update={(cache, { data }) => {
          if (!data?.logIn) return;

          updateApolloCacheAfterLogin(cache, data?.logIn);
        }}
      >
        {(logIn, { data, error, loading }) => {
          const hasErrors = data?.logIn && !!data.logIn.errors;

          return (
            <LogIn
              logIn={logIn}
              isLoading={loading}
              hasError={!!hasErrors}
              onRetrievePassword={onRetrievePassword}
            />
          );
        }}
      </Mutation>
    </Wrapper>
  );
};

export default LogInContainer;
