import React from 'react';
import { ConfirmModal } from 'components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Attachment from 'types/Attachment';
import REMOVE_ATTACHMENTS_FROM_ATTACHMENT_ALBUM from 'graphql/mutations/removeAttachmentsFromAttachmentAlbum.graphql';
import { useBoolean } from 'hooks';
import { useMutation } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';

interface Props {
  attachments: Attachment[];
  isAttachmentAlbumMedia?: boolean;
  onCompleted?: Function;
  children: (onClick: () => void) => React.ReactNode;
}
interface MutationData {
  removeAttachmentsFromAttachmentAlbum: {
    success?: boolean;
    errors?: string;
  };
}

export default function RemoveAttachmentAlbumMedia(props: Props) {
  const { attachments, onCompleted, children } = props;

  const { id } = useParams();

  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] =
    useBoolean(false);

  const attachmentIds = attachments.map((a) => a.id);

  const [removeAttachments, { loading: isLoading }] = useMutation<MutationData>(
    REMOVE_ATTACHMENTS_FROM_ATTACHMENT_ALBUM,
    {
      variables: { ids: attachmentIds, albumId: id },
      onCompleted: (data) => {
        if (data && data.removeAttachmentsFromAttachmentAlbum.success) {
          flashMessage('AlbumsMedia__DeleteMessage');
          onCompleted && onCompleted();
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }

        closeConfirmModal();
      },
      refetchQueries: ['AttachmentAlbum'],
    }
  );

  return (
    <>
      {children(openConfirmModal)}

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={removeAttachments}
        isLoading={isLoading}
        heading="RemoveAttachmentFromAlbum__ConfirmHeader"
      >
        <FormattedMessage
          id="RemoveAttachmentFromAlbum__ConfirmBody"
          values={{ count: attachments.length }}
        />
      </ConfirmModal>
    </>
  );
}
