import React, {
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import range from 'lodash/range';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { chatPageSize } from 'config';
import markChatRead from 'graphql/operations/markChatRead';
import { mapNodes, updateQuery } from 'helpers';
import RelayConnection from 'types/RelayConnection';
import Chat from 'types/Chat';
import TwilioMessage from 'types/TwilioMessage';
import { Loader, ErrorDebug } from 'components';
import { Message } from './components';
import { ScrollPosition } from './context';
import TWILIO_MESSAGES_QUERY from 'graphql/queries/twilioMessages.graphql';
import { useQuery } from '@apollo/react-hooks';
import useInfiniteScroll from 'react-infinite-scroll-hook';

interface Props {
  chat?: Chat;
  setIsThreadExpired: (isDisabled: boolean) => void;
}

interface QueryData {
  twilioMessages: RelayConnection<TwilioMessage>;
}

export default function MessageHistory({ chat, setIsThreadExpired }: Props) {
  const prevMessageCount = useRef(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = useRef<number>();
  const prevMostRecentMessageId = useRef<number>(0);

  const id = chat && chat.id;

  const queryVars = { chatId: id, pageSize: chatPageSize };

  const { data, loading, error, fetchMore } = useQuery<QueryData>(
    TWILIO_MESSAGES_QUERY,
    {
      variables: queryVars,
      fetchPolicy: 'network-only',
      skip: !id,
    }
  );

  const reversedMessages = useMemo(
    () =>
      uniqBy(
        [...mapNodes(data?.twilioMessages?.edges ?? [])],
        (msg) => msg.id
      ).reverse(),
    [data]
  );

  const loadNextPage =
    data && data.twilioMessages
      ? () =>
          fetchMore({
            variables: {
              ...queryVars,
              cursor: data.twilioMessages.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) =>
              updateQuery('twilioMessages', prev, fetchMoreResult),
          })
      : () => {};
  const hasNextPage = get(data, 'twilioMessages.pageInfo.hasNextPage');
  const msgCount =
    data && data.twilioMessages ? data.twilioMessages.edges.length : 0;
  const reversedIdx = range(msgCount).reverse();

  const mostRecentMessage = reversedMessages[reversedMessages.length - 1];

  const markRead = useCallback(() => {
    id && markChatRead(id);
  }, [id]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: !!loading,
    hasNextPage: !!hasNextPage,
    onLoadMore: loadNextPage,
    rootMargin: `200px 0px 0px 0px`,
  });

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      wrapperRef.current = node;
    },
    [rootRef]
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = wrapperRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    if (
      mostRecentMessage &&
      mostRecentMessage.id !== prevMostRecentMessageId.current
    ) {
      // New message, scroll right to the bottom and mark chat as read
      wrapper.scrollTop = wrapper.scrollHeight;
      prevMostRecentMessageId.current = mostRecentMessage.id;
      markRead();
    } else if (reversedMessages.length > prevMessageCount.current) {
      // Possibly received a new page, try to maintain scroll position
      // otherwise just go right to the bottom
      const scrollableRoot = wrapperRef.current;
      const lastScrollDistanceToBottom =
        lastScrollDistanceToBottomRef.current ?? 0;
      if (scrollableRoot) {
        console.log(
          'Scrolling from',
          scrollableRoot.scrollTop,
          'to',
          scrollableRoot.scrollHeight - lastScrollDistanceToBottom
        );
        scrollableRoot.scrollTop =
          scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
      }
    }

    prevMessageCount.current = reversedMessages.length;
  }, [mostRecentMessage, reversedMessages, markRead]);

  // Mark read on mount
  useEffect(() => {
    markRead();
  }, [markRead]);

  useLayoutEffect(() => {
    console.log('Scrolling to bottom');
    scrollToBottom();
  }, [scrollToBottom]);

  return (
    <div
      className="hide-scrollbar border-soild flex-1 overflow-auto border-b border-lightBorder p-2"
      ref={rootRefSetter}
      onScroll={handleRootScroll}
    >
      {!chat ? null : loading ? (
        <Loader />
      ) : error ? (
        <ErrorDebug error={error} />
      ) : (
        data &&
        data.twilioMessages && (
          <ScrollPosition.Provider
            value={{
              scrollToBottom,
              element: wrapperRef.current,
            }}
          >
            <>
              {hasNextPage && (
                <div ref={sentryRef} className="m-auto">
                  <Loader />
                </div>
              )}
              {reversedMessages.map((message, idx) => (
                <Message
                  key={message.id}
                  message={message}
                  setIsThreadExpired={setIsThreadExpired}
                  chat={chat}
                  isMostRecentMessage={mostRecentMessage?.id === message.id}
                  firstInPage={reversedIdx[idx] % chatPageSize === 0}
                />
              ))}
            </>
          </ScrollPosition.Provider>
        )
      )}
    </div>
  );
}
