#import './RecipientContactMethodFields.graphql'
#import './RecipientPublisherUserFields.graphql'
#import './RecipientPublisherTagFields.graphql'

fragment SuggestionIntendedRecipients on Suggestion {
  intendedRecipients {
    id
    combinedId
    protocol
    publisherUser {
      ...RecipientPublisherUserFields
    }
    contactMethod {
      ...RecipientContactMethodFields
    }
    publisherTag {
      ...RecipientPublisherTagFields
    }
    publisher {
      ...RecipientPublisherFields
    }
  }
}
