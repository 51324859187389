#import '../fragments/RecipientContactMethodFields.graphql'
#import '../fragments/RecipientPublisherUserFields.graphql'

mutation UpdateIntendedRecipients(
  $suggestionIds: [ID!]!
  $intendedRecipients: [IntendedRecipientInput!]!
) {
  updateIntendedRecipients(
    suggestionIds: $suggestionIds
    intendedRecipients: $intendedRecipients
  ) {
    suggestions {
      id
      updatedAt
      intendedRecipients {
        id
        combinedId
        protocol
        publisherUser {
          ...RecipientPublisherUserFields
        }
        contactMethod {
          ...RecipientContactMethodFields
        }
        publisher {
          ...RecipientPublisherFields
        }
        publisherTag {
          id
          name
          publishers {
            id
            name
            identities {
              id
              twitterUploadLong
            }
          }
        }
      }
    }
    errors
  }
}
