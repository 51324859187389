import UPDATE_SUGGESTION from 'graphql/mutations/updateSuggestion.graphql';
import apolloClient from '../apolloClient';
import SuggestionParams from 'types/SuggestionParams';

const updateSuggestion = async (
  id: number | string,
  updates: Partial<SuggestionParams>,
  refetchBatchSuggestionQuery: boolean = false
) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: UPDATE_SUGGESTION,
    variables: { id, updates },
    refetchQueries: refetchBatchSuggestionQuery ? ['BatchSuggestion'] : [],
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default updateSuggestion;
