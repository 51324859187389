fragment RecipientPublisherFields on Publisher {
  id
  name
  image
  identities {
    id
    twitterUploadLong
  }
  suggester {
    id
    accountName
    accountConfiguration {
      id
      logoUrl
    }
  }
}
