#import '../fragments/AttachmentFields.graphql'

query Attachments($cursor: String, $filter: JSON) {
  attachments(first: 100, after: $cursor, filter: $filter) {
    totalCount
    maxCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...AttachmentFields
        hasSuggestions
        hasDraftSuggestions
        title
        mediaAppearances {
          id
          publisherNameFound
          publisher {
            id
            name
            image
          }
          sentimentTag
        }
      }
    }
  }
}
