#import "../fragments/CurrentUserFields.graphql"
#import "../fragments/SemiLoggedInUserFields.graphql"

mutation ResetPassword(
  $resetPasswordToken: String!
  $password: String!
  $passwordConfirmation: String!
) {
  resetPassword(
    resetPasswordToken: $resetPasswordToken
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    errors
    token
    semiLoggedInUser {
      ...SemiLoggedInUserFields
    }
    currentUser {
      ...CurrentUserFields
    }
  }
}
