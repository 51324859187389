import React, { Suspense, lazy, useState } from 'react';
import values from 'lodash/values';
import { routes, thirdPartyLogin } from 'helpers';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Loading from 'screens/Loading';
import Error from 'screens/Error';
import GlobalNavBar from 'components/GlobalNavBar';
import CURRENT_USER_QUERY from 'graphql/queries/currentUser.graphql';
import { Helmet } from 'react-helmet';
import { LastLocation, Loader } from 'components';
import User from 'types/User';
import history from 'appHistory';
import {
  GlobalStyle,
  UtilityStyle,
  AccountPausedModal,
  UploadSaver,
  FlashMessage,
  MfaRequiredModal,
  InvalidOauthTokenNag,
  NoCurrentSuggester,
  ContentIntegrationWarning,
} from './components';
import MediaUpload from './components/MediaUpload/MediaUpload';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import { MfaSetup } from 'screens/Auth/screens';
import { useQuery } from 'react-apollo';
import TwitterAPIWarning from './components/TwitterAPIWarning';
import UploaderLayout from './components/UploaderLayout';
import { useAppSelector } from 'redux/store';

const Auth = lazy(() => import('screens/Auth'));
const AthleteDemographics = lazy(
  () => import('screens/Athlete/AthleteDemographics')
);
const CommentModeration = lazy(
  () => import('screens/Athlete/CommentModeration')
);
const DraftBuilder = lazy(() => import('screens/DraftBuilder'));
const DraftsIndex = lazy(() => import('screens/DraftsIndex'));
const Feed = lazy(() => import('screens/Feed'));
const Insights = lazy(() => import('screens/Insights'));
const Invitation = lazy(() => import('screens/Invitation'));
const MediaLibrary = lazy(() => import('screens/MediaLibrary'));
const Onboarding = lazy(() => import('screens/Onboarding'));
const PublisherShow = lazy(() => import('screens/PublisherShow'));
const PublisherTagsIndex = lazy(() => import('screens/PublisherTagsIndex'));
const PublishersIndex = lazy(() => import('screens/PublishersIndex'));
const Reports = lazy(() => import('screens/Reports'));
const Settings = lazy(() => import('screens/Settings'));
const TrackingIndex = lazy(() => import('screens/TrackingIndex'));
const TrackingShow = lazy(() => import('screens/TrackingShow'));
const OnboardingComplete = lazy(
  () => import('screens/Onboarding/components/OnboardingComplete')
);

const SHOW_TWITTER_API_WARNING = false;

interface QueryData {
  currentUser?: User;
  semiLoggedInUser?: SemiLoggedInUser;
}

export default function Layout() {
  const defaultRoute = routes.mediaLibrary.index;

  // Store the password when a user is signing up so that they don't have to
  // enter it again when setting up MFA
  const [password, setPassword] = useState('');

  const { data, loading, error } = useQuery<QueryData>(CURRENT_USER_QUERY);
  const currentUser = data?.currentUser;
  const semiLoggedInUser = data?.semiLoggedInUser;

  const isTopWarningBannerOpen = useAppSelector(
    (state) => state.ui.isTopWarningBannerOpen
  );

  // If a user enabled 2FA on a different device we want to force them to
  // re-login on this device
  if (
    semiLoggedInUser &&
    semiLoggedInUser.enableMfa &&
    window.localStorage.getItem('satellizer_token')
  ) {
    window.localStorage.setItem('satellizer_token', '');
    window.location.href = '/';
    return null;
  }

  if (currentUser && currentUser.currentSuggester) thirdPartyLogin(currentUser);

  const isUploader = currentUser?.currentRole === 'uploader';

  const showNavigation =
    currentUser &&
    !isUploader &&
    currentUser.currentSuggester?.onboardingCompleted &&
    (currentUser.enableMfa ||
      !currentUser.currentSuggester.accountConfiguration.requireMfa);

  const noCurrentSuggester =
    !window.location.pathname.match('invites') &&
    !isUploader &&
    ((currentUser && !currentUser.currentSuggester) ||
      (semiLoggedInUser && !semiLoggedInUser.currentSuggester));

  return (
    <>
      <GlobalStyle isTopWarningBannerOpen={isTopWarningBannerOpen} />
      <UtilityStyle />

      {noCurrentSuggester ? (
        <NoCurrentSuggester user={currentUser || semiLoggedInUser!} />
      ) : (
        <Router history={history}>
          <LastLocation.Provider>
            <Helmet title="Socialie" />

            {currentUser && currentUser.currentSuggester && (
              <>
                {!isUploader && (
                  <>
                    <InvalidOauthTokenNag
                      suggester={currentUser.currentSuggester}
                    />
                    <MediaUpload presentation="modal" />
                  </>
                )}

                <GlobalNavBar
                  hasNavigation={!!showNavigation}
                  currentUser={currentUser}
                />
              </>
            )}

            <FlashMessage />

            <AccountPausedModal
              paused={!!currentUser?.currentSuggester?.paused}
            />

            {currentUser && <MfaRequiredModal user={currentUser} />}

            <Suspense fallback={<Loader />}>
              {loading ? (
                <Loading />
              ) : error ? (
                <Error error={error} />
              ) : !semiLoggedInUser?.enableMfa &&
                semiLoggedInUser?.currentSuggester?.accountConfiguration
                  .requireMfa ? (
                <Switch>
                  <Route path={routes.auth.mfaSetup}>
                    <MfaSetup
                      user={semiLoggedInUser}
                      password={password}
                      onMfaSetupComplete={() => {
                        window.location.href = routes.mediaLibrary.index;
                      }}
                    />
                  </Route>
                  <Redirect to={routes.auth.mfaSetup} />
                </Switch>
              ) : isUploader ? (
                <UploaderLayout password={password} />
              ) : currentUser?.currentSuggester &&
                !currentUser.currentSuggester.onboardingCompleted ? (
                <Switch>
                  <Route path={routes.onboarding.root} component={Onboarding} />
                  <Route path={routes.auth.mfaSetup}>
                    <MfaSetup
                      user={currentUser}
                      password={password}
                      redirectPath={routes.onboarding.root}
                    />
                  </Route>

                  <Redirect
                    to={
                      currentUser?.enableMfa
                        ? routes.onboarding.root
                        : routes.auth.mfaSetup
                    }
                  />
                </Switch>
              ) : !currentUser ? (
                <Auth
                  semiLoggedInUser={semiLoggedInUser}
                  onRetrievePassword={setPassword}
                />
              ) : (
                <Switch>
                  <Route path={routes.auth.mfaSetup}>
                    <MfaSetup user={currentUser} password={password} />
                  </Route>

                  <Route path={routes.feed.insights}>
                    <Insights />
                  </Route>

                  <Route path={routes.feed.index}>
                    <Feed />
                  </Route>

                  <Route
                    path={routes.tracking.drafts}
                    render={(routeProps) => (
                      <DraftsIndex key="draft-suggestions" {...routeProps} />
                    )}
                  />

                  <Route
                    path={routes.tracking.pending}
                    render={(routeProps) => (
                      <DraftsIndex
                        key="pending-suggestions"
                        {...routeProps}
                        pending
                      />
                    )}
                  />

                  <Route path={routes.tracking.show} component={TrackingShow} />

                  <Route
                    path={routes.tracking.index}
                    component={TrackingIndex}
                  />

                  <Route
                    path={routes.onboarding.complete}
                    component={OnboardingComplete}
                  />

                  <Route
                    path={routes.publishers.tags}
                    component={PublisherTagsIndex}
                  />

                  <Route
                    path={routes.publishers.show}
                    component={PublisherShow}
                  />

                  <Route
                    path={routes.publishers.index}
                    component={PublishersIndex}
                  />

                  <Route path={routes.reports.index} component={Reports} />

                  <Route
                    path={routes.mediaLibrary.root}
                    component={MediaLibrary}
                  />

                  {values(routes.draftBuilder).map((path) => (
                    <Route
                      key={path}
                      exact
                      path={path}
                      component={DraftBuilder}
                    />
                  ))}

                  <Route path={routes.settings.index} component={Settings} />

                  <Route path={routes.invitation} component={Invitation} />

                  <Route
                    path={routes.athlete.index}
                    component={CommentModeration}
                  />
                  <Route
                    path={routes.athlete.demographics}
                    component={AthleteDemographics}
                  />

                  {/* Redirect to the default route */}
                  <Route render={() => <Redirect to={defaultRoute} />} />
                </Switch>
              )}
            </Suspense>

            <UploadSaver />
            {!isUploader && (
              <ContentIntegrationWarning currentUser={currentUser} />
            )}
            {SHOW_TWITTER_API_WARNING && <TwitterAPIWarning />}
          </LastLocation.Provider>
        </Router>
      )}
    </>
  );
}
