#import '../fragments/TwilioMessageFields.graphql'

query Chats($cursor: String, $unreadOnly: Boolean) {
  currentSuggester {
    id
    hasUnreadChatMessages
  }

  chats(first: 15, after: $cursor, unreadOnly: $unreadOnly) {
    pageInfo {
      hasNextPage
      endCursor
    }

    edges {
      node {
        id
        phoneNumber
        isUnread
        contactMethods {
          id
          value
          name
        }
        publishers {
          id
          name
          image
          online
        }
        latestMessage {
          ...TwilioMessageFields
        }
      }
    }
  }
}
