fragment SemiLoggedInUserFields on SemiLoggedInUser {
  id
  name
  email
  enableMfa
  mfaProtocol
  mfaPhone
  publisherRedirectUrl
  currentSuggester {
    id
    brand
    accountName
    accountConfiguration {
      id
      requireMfa
      logoUrl
    }
  }
  suggesters {
    id
    brand
    accountName
    accountConfiguration {
      id
      logoUrl
    }
    children {
      id
      brand
      accountName
      accountConfiguration {
        id
        logoUrl
      }
    }
  }
}
