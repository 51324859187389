import UPDATE_INTENDED_RECIPIENTS from 'graphql/mutations/updateIntendedRecipients.graphql';
import apolloClient from '../apolloClient';

interface IntendedRecipientInput {
  recipientType?: string;
  recipientId?: string | number;
}

interface Options {
  suggestionIds: number | string | (number | string)[];
  intendedRecipients: IntendedRecipientInput[];
}

const updateIntendedRecipients = async (options: Options) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: UPDATE_INTENDED_RECIPIENTS,
    variables: options,
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default updateIntendedRecipients;
