mutation downloadMedia(
  $socketId: String!
  $suggestionIds: [ID!]
  $attachmentIds: [ID!]
  $requestSource: String
) {
  downloadMedia(
    socketId: $socketId
    suggestionIds: $suggestionIds
    attachmentIds: $attachmentIds
    requestSource: $requestSource
  ) {
    attachments {
      id
    }
    url
    errors
  }
}
