import ADD_ATTACHMENT from 'graphql/mutations/addAttachment.graphql';
import SUGGESTION_ATTACHMENTS from 'graphql/fragments/SuggestionAttachments.graphql';
import apolloClient from '../apolloClient';
import { FileUploader } from 'helpers/upload/FileUploader';

interface Options {
  suggestionId?: number | string;
  uploader: FileUploader;
  socketId: string;
  uploadId: string;
  mmsMessage?: Boolean;
  isMediaLibraryUpload?: boolean;
  skipAutomations?: boolean;
  publisherIds?: Array<number>;
}

const addAttachment = async (options: Options) => {
  const {
    suggestionId,
    uploader,
    socketId,
    uploadId,
    mmsMessage,
    isMediaLibraryUpload,
    publisherIds,
  } = options;
  const { data } = await apolloClient.mutate({
    mutation: ADD_ATTACHMENT,
    variables: {
      mmsMessage,
      params: {
        suggestionIds: [suggestionId],
        source: isMediaLibraryUpload ? 'media_library' : null,
        skipAutomations: options.skipAutomations,
        publisherIds: publisherIds,
        ...uploader.attachmentData(),
      },
      socketId: `${socketId}:${uploadId}`,
    },
  });

  if (!data) return;

  apolloClient.writeFragment({
    id: `Suggestion:${suggestionId}`,
    fragment: SUGGESTION_ATTACHMENTS,
    fragmentName: 'SuggestionAttachments',
    data: {
      __typename: 'Suggestion',
      attachments: [data.addAttachment.attachment],
    },
  });

  return data.addAttachment.attachment;
};

export default addAttachment;
