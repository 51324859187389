mutation AddTagsToSuggestions($suggestionIds: [ID!]!, $tagIds: [ID!]!) {
  addTagsToSuggestions(suggestionIds: $suggestionIds, tagIds: $tagIds) {
    suggestions {
      id
      updatedAt
      tags {
        name
        id
      }
    }
    errors
  }
}
