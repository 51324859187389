#import './RecipientPublisherFields.graphql'

fragment RecipientPublisherUserFields on PublisherUser {
  id
  userId
  name
  notificationPreferences
  email
  phone
  twitterUsername
  pending
  inviteContactValue
  inviteProtocol
  hasApplePushPermission
  publisher {
    ...RecipientPublisherFields
  }
}
