import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

interface Props extends TippyProps {
  theme?:
    | 'dark'
    | 'socialie'
    | 'socialieNoPad'
    | 'socialieDark'
    | 'FlyoutMenu'
    | 'FlyoutMenuDark';
}

const Tooltip = ({ children, ...tooltipProps }: Props) => {
  return (
    <Tippy
      theme="socialie"
      duration={[150, 150]}
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      }}
      arrow={false}
      appendTo={() => document.body}
      {...tooltipProps}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
